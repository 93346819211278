import React from 'react';
import EditPolicyText from '../../components/pages/admin/applicationPolicies/superAdmin/EditPolicyText';
import { Policies } from '../../api/Policies';
import './AdminPanel.scss';
import useTitle from '../../helpers/useTitle';
import useLocale from '../../locale/useLocale';

const ApplicationPolicies = () => {
    const { l } = useLocale();
    useTitle(l('_applicationPoliciesTitleCase'));

    return (
        <>
            <EditPolicyText policy={Policies.PrivacyPolicy} />
            <EditPolicyText
                policy={Policies.TermsAndConditions}
                className="mt-6"
            />
            <EditPolicyText policy={Policies.CookiePolicy} className="mt-6" />
        </>
    );
};

export default ApplicationPolicies;
