import React from 'react';
import { Modal } from '@appkit4/react-components/modal';
import { Button } from '@appkit4/react-components/button';
import { Avatar } from '@appkit4/react-components/avatar';
import useGenericErrorToast from '../../../../../helpers/useGenericErrorToast';
import useToast from '../../../../../helpers/useToast';
import { UserResponse } from '../../../../../api/generated/models';
import useLocale from '../../../../../locale/useLocale';
import ModalContainer from '../../../../ModalContainer';
import {
    DeleteV1UserAdminUserIdMutationError,
    getGetV1UserAdminQueryKey,
    getGetV1TenantSuperAdminQueryKey,
    usePutV1UserAdminRestoreUserId,
} from '../../../../../api/generated/endpoint';
import { useQueryClient } from '@tanstack/react-query';

interface ConfirmRestoreUserModalProps {
    visible: boolean;
    userInfo: UserResponse | undefined;
    setNotVisible: () => void;
}

const ConfirmRestoreUserModal = (props: ConfirmRestoreUserModalProps) => {
    const showToast = useToast();
    const { l } = useLocale();
    const showGenericErrorToast = useGenericErrorToast();
    const restoreUser = usePutV1UserAdminRestoreUserId();
    const queryClient = useQueryClient();

    const handleConfirmRestore = () => {
        restoreUser.mutate(
            { userId: props.userInfo?.id ?? -1 },
            {
                onSuccess: () => {
                    showToast(l('_success'), l('_userRestored'), 'success');
                    props.setNotVisible();
                    queryClient.invalidateQueries(getGetV1UserAdminQueryKey());
                    queryClient.invalidateQueries(
                        getGetV1TenantSuperAdminQueryKey()
                    );
                },
                onError: (error: DeleteV1UserAdminUserIdMutationError) => {
                    showGenericErrorToast(error);
                    props.setNotVisible();
                },
            }
        );
    };

    return (
        <>
            {props.visible && (
                <ModalContainer isVisible={props.visible}>
                    <Modal
                        visible
                        closable
                        closeAriaLabel={l('_close')}
                        title={l('_confirmUserRestore')}
                        aria-label={`${l('_confirmUserRestoreWithEmail')} ${
                            props.userInfo?.email ?? '-'
                        }`}
                        onCancel={props.setNotVisible}
                        modalStyle={{
                            width: '50vw',
                            maxWidth: '500px',
                            minWidth: '250px',
                        }}
                        footerStyle={{ marginTop: '1rem' }}
                        footer={
                            <>
                                <Button
                                    kind="secondary"
                                    onClick={props.setNotVisible}
                                    className={'focus-margin'}
                                >
                                    {l('_cancel')}
                                </Button>
                                <Button
                                    kind="primary"
                                    onClick={() => handleConfirmRestore()}
                                    className={'focus-margin'}
                                >
                                    {l('_restore')}
                                </Button>
                            </>
                        }
                    >
                        <p>{l('_areYouSureYouWantToRestoreUser')}</p>
                        <div className="row mt-2">
                            <div className="col-auto">
                                <Avatar
                                    label={
                                        props.userInfo
                                            ? `${props.userInfo.forenames.charAt(
                                                  0
                                              )}${props.userInfo.surname.charAt(
                                                  0
                                              )}`
                                            : '-'
                                    }
                                    compact
                                />
                            </div>
                            <div className="col" style={{ paddingTop: '2px' }}>
                                {props.userInfo?.email}
                            </div>
                        </div>
                    </Modal>
                </ModalContainer>
            )}
        </>
    );
};

export default ConfirmRestoreUserModal;
