import Roles from '../../auth/roles';
import './AdminPanel.scss';
import useLocale from '../../locale/useLocale';
import { useGetV1IdentityUserInfo } from '../../api/generated/endpoint';
import useTitle from '../../helpers/useTitle';
import UsersTable from '../../components/pages/admin/management/shared/UsersTable';
import TenantsTable from '../../components/pages/admin/management/superAdmin/TenantsTable';
import TenantContactsTable from '../../components/pages/admin/tenant/superAdmin/TenantContactsTable';

const Management = () => {
    const { l } = useLocale();
    useTitle(l('_management'));

    const { data: userInfo } = useGetV1IdentityUserInfo({
        query: {
            staleTime: Infinity,
        },
    });

    return (
        <>
            {userInfo?.roleId === Roles.AdminId && (
                <>
                    <h1 aria-label="management-title">{l('_management')}</h1>
                    <UsersTable selectedTenantId={userInfo.tenantId} />
                    <div style={{ marginTop: '5.5rem' }}>
                        <TenantContactsTable tenantId={userInfo.tenantId} />
                    </div>
                </>
            )}

            {userInfo?.roleId === Roles.SuperAdminId && <TenantsTable />}
        </>
    );
};

export default Management;
