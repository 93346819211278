import React from 'react';
import { Modal } from '@appkit4/react-components/modal';
import useGenericErrorToast from '../../../../../helpers/useGenericErrorToast';
import useToast from '../../../../../helpers/useToast';
import CreateContactPersonForm, {
    FormValues,
} from '../../../../CreateContactPersonForm';
import {
    PostV1ContactPersonSuperAdminApplicationMutationError,
    getGetV1ContactPersonApplicationQueryKey,
    usePostV1ContactPersonSuperAdminApplication,
} from '../../../../../api/generated/endpoint';
import { useQueryClient } from '@tanstack/react-query';
import useLocale from '../../../../../locale/useLocale';

interface CreateApplicationContactModalProps {
    isVisible: boolean;
    setNotVisible: () => void;
}

const CreateApplicationContactModal = (
    props: CreateApplicationContactModalProps
) => {
    const showToast = useToast();
    const { l } = useLocale();
    const showGenericErrorToast = useGenericErrorToast();
    const createNewApplicationContact =
        usePostV1ContactPersonSuperAdminApplication();
    const queryClient = useQueryClient();

    const postNewApplicationContactRequest = (
        newApplicationContact: FormValues
    ) => {
        createNewApplicationContact.mutate(
            { data: newApplicationContact },
            {
                onSuccess: () => {
                    showToast(
                        l('_success'),
                        l('_newApplicationContactCreated'),
                        'success'
                    );
                    props.setNotVisible();
                    queryClient.invalidateQueries(
                        getGetV1ContactPersonApplicationQueryKey()
                    );
                },
                onError: (
                    error: PostV1ContactPersonSuperAdminApplicationMutationError
                ) => {
                    showGenericErrorToast(error);
                    props.setNotVisible();
                },
            }
        );
    };

    return (
        <>
            {props.isVisible && (
                <Modal
                    visible
                    closable
                    title={l('_createNewApplicationContact')}
                    aria-label={l('_createANewApplicationContact')}
                    modalStyle={{
                        width: '50vw',
                        maxWidth: '500px',
                        minWidth: '250px',
                    }}
                    footerStyle={{ marginTop: '1rem' }}
                    closeOnPressEscape
                    onCancel={() => props.setNotVisible()}
                >
                    <CreateContactPersonForm
                        submitFunc={postNewApplicationContactRequest}
                    />
                </Modal>
            )}
        </>
    );
};

export default CreateApplicationContactModal;
