import React, { useEffect, useState } from 'react';
import { Button } from '@appkit4/react-components/button';
import { useAuth } from 'react-oidc-context';
import './LandingPage.scss';
import { Navigate, useLocation } from 'react-router-dom';
import { AppRoutes, LoginRedirectStorageKey } from '../../Constants';
import PwCLogoWhite from '../../assets/PwC_Outline_Logo_White.png';
import useLocale from '../../locale/useLocale';
import useTitle from '../../helpers/useTitle';

const LandingPage = () => {
    const auth = useAuth();
    const location = useLocation();
    const { l } = useLocale();
    useTitle(l('_applicationName'));

    const landingPageGreetings: string[] = [
        l('_landingPageGreeting01'),
        l('_landingPageGreeting02'),
        l('_landingPageGreeting03'),
        l('_landingPageGreeting04'),
        l('_landingPageGreeting05'),
        l('_landingPageGreeting06'),
        l('_landingPageGreeting07'),
        l('_landingPageGreeting08'),
        l('_landingPageGreeting09'),
        l('_landingPageGreeting10'),
        l('_landingPageGreeting11'),
        l('_landingPageGreeting12'),
        l('_landingPageGreeting13'),
        l('_landingPageGreeting14'),
        l('_landingPageGreeting15'),
    ];

    const [greeting, setGreeting] = useState<string>('');

    useEffect(() => {
        document.body.classList.add('landing');

        setGreeting(
            landingPageGreetings[
                Math.floor(Math.random() * landingPageGreetings.length)
            ]
        );

        return () => {
            document.body.classList.remove('landing');
        };
    }, []);

    if (auth.isAuthenticated) {
        return <Navigate to={AppRoutes.HomePage} />;
    }

    const handleLogIn = () => {
        const returnRoute = location.state?.returnRoute;

        if (returnRoute) {
            window.sessionStorage.setItem(LoginRedirectStorageKey, returnRoute);
        }

        auth.signinRedirect();
    };

    return (
        <div>
            <img
                src={PwCLogoWhite as string}
                alt={l('_PwCLogo')}
                className="logo"
            />
            <div className="content">
                <h3 className="ap-mb-spacing-6 title">
                    {l('_applicationName')}
                </h3>
                <div className="w-50">
                    <h1 className="title text-wrap">{greeting}</h1>
                </div>
                {!auth.isAuthenticated && (
                    <div>
                        <Button
                            onClick={handleLogIn}
                            className="login-btn ap-mt-spacing-8"
                        >
                            {l('_logInUsingPwCSSO')}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default LandingPage;
