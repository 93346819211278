import React from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Input } from '@appkit4/react-components/field';
import { Button } from '@appkit4/react-components/button';
import {
    ContactPersonResponse,
    EditContactPersonRequest,
} from '../api/generated/models';
import useLocale from '../locale/useLocale';

interface EditContactPersonFormProps {
    currentContactPersonValues: ContactPersonResponse;
    submitFunc: (
        applicationContactId: number,
        editedContactPerson: EditContactPersonRequest
    ) => void;
}

export interface FormValues {
    forenames: string;
    surname: string;
    email: string;
}

const EditContactPersonForm = (props: EditContactPersonFormProps) => {
    const { l } = useLocale();

    const EditContactSchema = Yup.object({
        forenames: Yup.string()
            .trim()
            .max(100, l('_pleaseEnterFewerCharacters'))
            .required(l('_pleaseEnterForename(s)')),
        surname: Yup.string()
            .trim()
            .max(100, l('_pleaseEnterFewerCharacters'))
            .required(l('_pleaseEnterASurname')),
        email: Yup.string()
            .trim()
            .max(250, l('_pleaseEnterFewerCharacters'))
            .email(l('_pleaseEnterAnEmailAddress'))
            .required(l('_pleaseEnterAValidEmailAddress')),
    });

    return (
        <Formik
            initialValues={{
                forenames: props.currentContactPersonValues.forenames,
                surname: props.currentContactPersonValues.surname,
                email: props.currentContactPersonValues.email,
            }}
            validationSchema={EditContactSchema}
            onSubmit={(values) => {
                const editedContactPerson: EditContactPersonRequest = {
                    forenames: values.forenames,
                    surname: values.surname,
                    email: values.email,
                };
                props.submitFunc(
                    props.currentContactPersonValues.id,
                    editedContactPerson
                );
            }}
        >
            {({
                values,
                errors,
                touched,
                setFieldTouched,
                setFieldValue,
                validateField,
            }) => (
                <Form>
                    <div className="row focus-margin">
                        <div className="col">
                            <Input
                                type="text"
                                data-cy="forename"
                                title={l('_forename(s)')}
                                placeholder={l('_forename(s)')}
                                required
                                value={values.forenames}
                                onChange={(value: string) => {
                                    setFieldTouched('forenames');
                                    validateField('forenames');
                                    setFieldValue('forenames', value);
                                }}
                            />
                            {touched.forenames && errors.forenames && (
                                <div className="error-text">
                                    {errors.forenames}
                                </div>
                            )}
                        </div>

                        <div className="col">
                            <Input
                                type="text"
                                data-cy="surname"
                                title={l('_surname')}
                                placeholder={l('_surname')}
                                required
                                value={values.surname}
                                onChange={(value: string) => {
                                    setFieldTouched('surname');
                                    validateField('surname');
                                    setFieldValue('surname', value);
                                }}
                            />
                            {touched.surname && errors.surname && (
                                <div className="error-text">
                                    {errors.surname}
                                </div>
                            )}
                        </div>
                    </div>

                    <Input
                        type="text"
                        data-cy="email"
                        title={l('_email')}
                        placeholder={l('_email')}
                        required
                        value={values.email}
                        onChange={(value: string) => {
                            setFieldTouched('email');
                            validateField('email');
                            setFieldValue('email', value);
                        }}
                        className="mt-3"
                    />
                    {touched.email && errors.email && (
                        <div className="error-text">{errors.email}</div>
                    )}

                    <Button className="ms-auto mt-5" type="submit">
                        {l('_updateContact')}
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

export default EditContactPersonForm;
