import React from 'react';
import { Button } from '@appkit4/react-components/button';
import { TenantResponse } from '../../../../../api/generated/models';
import Roles from '../../../../../auth/roles';
import useLocale from '../../../../../locale/useLocale';
import { useGetV1IdentityUserInfo } from '../../../../../api/generated/endpoint';

interface TenantActionsCellProps {
    tenant: TenantResponse;
    displayRestoreTenantModal: () => void;
}

const UserActionsCell = (props: TenantActionsCellProps) => {
    const { l } = useLocale();

    /* Load current user info */
    const { data: userInfo } = useGetV1IdentityUserInfo({
        query: {
            staleTime: Infinity,
        },
    });

    return (
        <div>
            {userInfo?.roleId === Roles.SuperAdminId && (
                <>
                    <div className="d-flex justify-content-center">
                        <Button
                            className="py-0 px-1 h-50"
                            kind="text"
                            icon="icon-circle-plus-outline"
                            type="button"
                            role="button"
                            compact
                            onClick={() => props.displayRestoreTenantModal()}
                            title={`${l('_restoreTenant')} ${
                                props.tenant.name
                            }`}
                            data-testid="restore-tenant-icon-button"
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default UserActionsCell;
