import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../Constants';
import './GlobalFooter.scss';
import useLocale from '../../locale/useLocale';

const GlobalFooter = () => {
    const { l } = useLocale();
    const currentYear = new Date().getFullYear();
    const footerContent = `© ${currentYear} ${l('_PwCDisclaimer')}`;
    const footerLinks = [
        { name: l('_privacyPolicy'), href: AppRoutes.PrivacyPage },
        { name: l('_termsAndConditions'), href: AppRoutes.TandC },
        { name: l('_cookiePolicy'), href: AppRoutes.CookiesPage },
        { name: l('_contactUs'), href: AppRoutes.ContactPage },
        {
            name: l('_accessibilityStatement'),
            href: AppRoutes.AccessibilityStatement,
        },
    ];

    const navigate = useNavigate();

    const footerClick = (url: string) => {
        navigate(url);
    };
    return (
        <div className="footer-wrapper text-center position-relative mt-5 p-2 overflow-hidden">
            <footer role="contentinfo" className="ap-footer ap-text-neutral-01">
                <div className="ap-footer-content" data-testid="footer-content">
                    {footerContent}
                </div>
                <div className="ap-footer-link-group d-inline-flex row">
                    {footerLinks.map((link) => (
                        <div
                            className="ap-footer-link d-inline-flex row col-md footer-link-divider"
                            key={link.href}
                        >
                            <button
                                className="ap-link ap-text-neutral-01 bg-transparent
								text-nowrap col-md"
                                onClick={() => footerClick(link.href)}
                                role="link"
                                type="button"
                            >
                                {link.name}
                            </button>
                        </div>
                    ))}
                </div>
            </footer>
        </div>
    );
};

export default GlobalFooter;
