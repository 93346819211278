import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { NotFound } from '../../../pages';
import Roles from '../../../auth/roles';
import { useGetV1IdentityUserInfo } from '../../../api/generated/endpoint';

type SuperAdminRedirectProps = { children?: JSX.Element };

const SuperAdminRedirect: FC<SuperAdminRedirectProps> = ({ children }) => {
    const { data: userData } = useGetV1IdentityUserInfo({
        query: {
            staleTime: Infinity,
        },
    });

    if (userData?.roleId === Roles.SuperAdminId) {
        return children || <Outlet />;
    }

    return <NotFound />;
};

export default SuperAdminRedirect;
