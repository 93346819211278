import React from 'react';
import { Button } from '@appkit4/react-components/button';
import { UserResponse } from '../../../../../api/generated/models';
import Roles from '../../../../../auth/roles';
import useLocale from '../../../../../locale/useLocale';
import { useGetV1IdentityUserInfo } from '../../../../../api/generated/endpoint';
import { UserStatuses } from './UsersTable';

interface UserActionsCellProps {
    user: UserResponse;
    userStatus: UserStatuses;
    displayEditUserModal: () => void;
    displayDeleteUserModal: () => void;
    displayRestoreUserModal: () => void;
}

const UserActionsCell = (props: UserActionsCellProps) => {
    const { l } = useLocale();

    /* Load current user info */
    const { data: userInfo } = useGetV1IdentityUserInfo({
        query: {
            staleTime: Infinity,
        },
    });

    return (
        <div>
            {props.user.id != userInfo?.id && // cannot edit or delete yourself
                (props.user.roleName === 'User' || // admin can edit or delete users
                    props.user.roleName === 'Admin' || // admin can edit or delete admins
                    userInfo?.roleId === Roles.SuperAdminId) && ( // super admin can edit or delete all roles
                    <>
                        {props.userStatus === UserStatuses.Active ? (
                            <>
                                <div className="actions-icons d-flex flex-row justify-content-between">
                                    <Button
                                        className="m-0 py-0 px-1 h-50"
                                        kind="text"
                                        icon="icon-edit-outline"
                                        type="button"
                                        role="button"
                                        compact
                                        onClick={() => {
                                            props.displayEditUserModal();
                                        }}
                                        title={`${l('_editUser')} ${
                                            props.user.forenames
                                        } ${props.user.surname}`}
                                        data-testid="edit-user-icon-button"
                                    />

                                    <Button
                                        className="m-0 py-0 px-1 h-50"
                                        kind="text"
                                        icon="icon-delete-outline"
                                        type="button"
                                        role="button"
                                        compact
                                        onClick={() =>
                                            props.displayDeleteUserModal()
                                        }
                                        title={`${l('_deleteUser')} ${
                                            props.user.forenames
                                        } ${props.user.surname}`}
                                        data-testid="delete-user-icon-button"
                                    />
                                </div>
                            </>
                        ) : (
                            <div className="d-flex justify-content-center">
                                <Button
                                    className="m-0 py-0 px-1 h-50"
                                    kind="text"
                                    icon="icon-circle-plus-outline"
                                    type="button"
                                    role="button"
                                    compact
                                    onClick={() =>
                                        props.displayRestoreUserModal()
                                    }
                                    title={`${l('_restoreUser')} ${
                                        props.user.forenames
                                    } ${props.user.surname}`}
                                    data-testid="restore-user-icon-button"
                                />
                            </div>
                        )}
                    </>
                )}
        </div>
    );
};

export default UserActionsCell;
