/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * PwC Web App Accelerator API
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
    MutationFunction,
    QueryFunction,
    QueryKey,
    UseMutationOptions,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult,
} from '@tanstack/react-query';
import type {
    ApplicationVersionResponse,
    ContactPersonResponse,
    CreateApplicationContactRequest,
    CreateTenantContactRequest,
    CreateTenantRequest,
    CreateUserRequest,
    EditContactPersonRequest,
    EditPolicyRequest,
    EditTenantRequest,
    EditUserRequest,
    GetV1BusinessMetricsSuperAdminUserLoginsParams,
    GetV1TenantSuperAdminParams,
    GetV1UserAdminParams,
    PaginatedTenantResponse,
    PaginatedUserLoginsResponse,
    PaginatedUserResponse,
    PolicyResponse,
    PolicyVersionResponse,
    PutV1TenantSuperAdminRestoreTenantIdParams,
    RoleResponse,
    TenantResponse,
    UserInfoResponse,
    UserLoginsSummaryResponse,
    UserPreferencesRequest,
    UserPreferencesResponse,
    UserResponse,
} from './models';
import { useCustomAxios } from '../mutator/custom-instance';
import type { ErrorType } from '../mutator/custom-instance';

/**
 * @summary Create a new tenant contact person
 */
export const usePostV1ContactPersonAdminTenantHook = () => {
    const postV1ContactPersonAdminTenant =
        useCustomAxios<ContactPersonResponse>();

    return (createTenantContactRequest: CreateTenantContactRequest) => {
        return postV1ContactPersonAdminTenant({
            url: `/v1/ContactPerson/Admin/Tenant`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: createTenantContactRequest,
        });
    };
};

export const usePostV1ContactPersonAdminTenantMutationOptions = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<ReturnType<typeof usePostV1ContactPersonAdminTenantHook>>
        >,
        TError,
        { data: CreateTenantContactRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<
        ReturnType<ReturnType<typeof usePostV1ContactPersonAdminTenantHook>>
    >,
    TError,
    { data: CreateTenantContactRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const postV1ContactPersonAdminTenant =
        usePostV1ContactPersonAdminTenantHook();

    const mutationFn: MutationFunction<
        Awaited<
            ReturnType<ReturnType<typeof usePostV1ContactPersonAdminTenantHook>>
        >,
        { data: CreateTenantContactRequest }
    > = (props) => {
        const { data } = props ?? {};

        return postV1ContactPersonAdminTenant(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type PostV1ContactPersonAdminTenantMutationResult = NonNullable<
    Awaited<
        ReturnType<ReturnType<typeof usePostV1ContactPersonAdminTenantHook>>
    >
>;
export type PostV1ContactPersonAdminTenantMutationBody =
    CreateTenantContactRequest;
export type PostV1ContactPersonAdminTenantMutationError = ErrorType<void>;

/**
 * @summary Create a new tenant contact person
 */
export const usePostV1ContactPersonAdminTenant = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<ReturnType<typeof usePostV1ContactPersonAdminTenantHook>>
        >,
        TError,
        { data: CreateTenantContactRequest },
        TContext
    >;
}): UseMutationResult<
    Awaited<
        ReturnType<ReturnType<typeof usePostV1ContactPersonAdminTenantHook>>
    >,
    TError,
    { data: CreateTenantContactRequest },
    TContext
> => {
    const mutationOptions =
        usePostV1ContactPersonAdminTenantMutationOptions(options);

    return useMutation(mutationOptions);
};

export const usePutV1ContactPersonAdminContactPersonIdHook = () => {
    const putV1ContactPersonAdminContactPersonId = useCustomAxios<void>();

    return (
        contactPersonId: number,
        editContactPersonRequest: EditContactPersonRequest
    ) => {
        return putV1ContactPersonAdminContactPersonId({
            url: `/v1/ContactPerson/Admin/${contactPersonId}`,
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            data: editContactPersonRequest,
        });
    };
};

export const usePutV1ContactPersonAdminContactPersonIdMutationOptions = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<typeof usePutV1ContactPersonAdminContactPersonIdHook>
            >
        >,
        TError,
        { contactPersonId: number; data: EditContactPersonRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<
        ReturnType<
            ReturnType<typeof usePutV1ContactPersonAdminContactPersonIdHook>
        >
    >,
    TError,
    { contactPersonId: number; data: EditContactPersonRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const putV1ContactPersonAdminContactPersonId =
        usePutV1ContactPersonAdminContactPersonIdHook();

    const mutationFn: MutationFunction<
        Awaited<
            ReturnType<
                ReturnType<typeof usePutV1ContactPersonAdminContactPersonIdHook>
            >
        >,
        { contactPersonId: number; data: EditContactPersonRequest }
    > = (props) => {
        const { contactPersonId, data } = props ?? {};

        return putV1ContactPersonAdminContactPersonId(contactPersonId, data);
    };

    return { mutationFn, ...mutationOptions };
};

export type PutV1ContactPersonAdminContactPersonIdMutationResult = NonNullable<
    Awaited<
        ReturnType<
            ReturnType<typeof usePutV1ContactPersonAdminContactPersonIdHook>
        >
    >
>;
export type PutV1ContactPersonAdminContactPersonIdMutationBody =
    EditContactPersonRequest;
export type PutV1ContactPersonAdminContactPersonIdMutationError =
    ErrorType<void>;

export const usePutV1ContactPersonAdminContactPersonId = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<typeof usePutV1ContactPersonAdminContactPersonIdHook>
            >
        >,
        TError,
        { contactPersonId: number; data: EditContactPersonRequest },
        TContext
    >;
}): UseMutationResult<
    Awaited<
        ReturnType<
            ReturnType<typeof usePutV1ContactPersonAdminContactPersonIdHook>
        >
    >,
    TError,
    { contactPersonId: number; data: EditContactPersonRequest },
    TContext
> => {
    const mutationOptions =
        usePutV1ContactPersonAdminContactPersonIdMutationOptions(options);

    return useMutation(mutationOptions);
};

export const useDeleteV1ContactPersonAdminContactPersonIdHook = () => {
    const deleteV1ContactPersonAdminContactPersonId = useCustomAxios<void>();

    return (contactPersonId: number) => {
        return deleteV1ContactPersonAdminContactPersonId({
            url: `/v1/ContactPerson/Admin/${contactPersonId}`,
            method: 'DELETE',
        });
    };
};

export const useDeleteV1ContactPersonAdminContactPersonIdMutationOptions = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof useDeleteV1ContactPersonAdminContactPersonIdHook
                >
            >
        >,
        TError,
        { contactPersonId: number },
        TContext
    >;
}): UseMutationOptions<
    Awaited<
        ReturnType<
            ReturnType<typeof useDeleteV1ContactPersonAdminContactPersonIdHook>
        >
    >,
    TError,
    { contactPersonId: number },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const deleteV1ContactPersonAdminContactPersonId =
        useDeleteV1ContactPersonAdminContactPersonIdHook();

    const mutationFn: MutationFunction<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof useDeleteV1ContactPersonAdminContactPersonIdHook
                >
            >
        >,
        { contactPersonId: number }
    > = (props) => {
        const { contactPersonId } = props ?? {};

        return deleteV1ContactPersonAdminContactPersonId(contactPersonId);
    };

    return { mutationFn, ...mutationOptions };
};

export type DeleteV1ContactPersonAdminContactPersonIdMutationResult =
    NonNullable<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof useDeleteV1ContactPersonAdminContactPersonIdHook
                >
            >
        >
    >;

export type DeleteV1ContactPersonAdminContactPersonIdMutationError =
    ErrorType<void>;

export const useDeleteV1ContactPersonAdminContactPersonId = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof useDeleteV1ContactPersonAdminContactPersonIdHook
                >
            >
        >,
        TError,
        { contactPersonId: number },
        TContext
    >;
}): UseMutationResult<
    Awaited<
        ReturnType<
            ReturnType<typeof useDeleteV1ContactPersonAdminContactPersonIdHook>
        >
    >,
    TError,
    { contactPersonId: number },
    TContext
> => {
    const mutationOptions =
        useDeleteV1ContactPersonAdminContactPersonIdMutationOptions(options);

    return useMutation(mutationOptions);
};

export const usePostV1AuthLogoutHook = () => {
    const postV1AuthLogout = useCustomAxios<void>();

    return () => {
        return postV1AuthLogout({ url: `/v1/Auth/Logout`, method: 'POST' });
    };
};

export const usePostV1AuthLogoutMutationOptions = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof usePostV1AuthLogoutHook>>>,
        TError,
        void,
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostV1AuthLogoutHook>>>,
    TError,
    void,
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const postV1AuthLogout = usePostV1AuthLogoutHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof usePostV1AuthLogoutHook>>>,
        void
    > = () => {
        return postV1AuthLogout();
    };

    return { mutationFn, ...mutationOptions };
};

export type PostV1AuthLogoutMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof usePostV1AuthLogoutHook>>>
>;

export type PostV1AuthLogoutMutationError = ErrorType<void>;

export const usePostV1AuthLogout = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof usePostV1AuthLogoutHook>>>,
        TError,
        void,
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof usePostV1AuthLogoutHook>>>,
    TError,
    void,
    TContext
> => {
    const mutationOptions = usePostV1AuthLogoutMutationOptions(options);

    return useMutation(mutationOptions);
};

export const useGetV1ContactPersonTenantTenantIdHook = () => {
    const getV1ContactPersonTenantTenantId =
        useCustomAxios<ContactPersonResponse[]>();

    return (tenantId: number) => {
        return getV1ContactPersonTenantTenantId({
            url: `/v1/ContactPerson/Tenant/${tenantId}`,
            method: 'GET',
        });
    };
};

export const getGetV1ContactPersonTenantTenantIdQueryKey = (
    tenantId: number
) => {
    return [`/v1/ContactPerson/Tenant/${tenantId}`] as const;
};

export const useGetV1ContactPersonTenantTenantIdQueryOptions = <
    TData = Awaited<
        ReturnType<ReturnType<typeof useGetV1ContactPersonTenantTenantIdHook>>
    >,
    TError = ErrorType<void>
>(
    tenantId: number,
    options?: {
        query?: UseQueryOptions<
            Awaited<
                ReturnType<
                    ReturnType<typeof useGetV1ContactPersonTenantTenantIdHook>
                >
            >,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ??
        getGetV1ContactPersonTenantTenantIdQueryKey(tenantId);

    const getV1ContactPersonTenantTenantId =
        useGetV1ContactPersonTenantTenantIdHook();

    const queryFn: QueryFunction<
        Awaited<
            ReturnType<
                ReturnType<typeof useGetV1ContactPersonTenantTenantIdHook>
            >
        >
    > = () => getV1ContactPersonTenantTenantId(tenantId);

    return {
        queryKey,
        queryFn,
        enabled: !!tenantId,
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<
            ReturnType<
                ReturnType<typeof useGetV1ContactPersonTenantTenantIdHook>
            >
        >,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1ContactPersonTenantTenantIdQueryResult = NonNullable<
    Awaited<
        ReturnType<ReturnType<typeof useGetV1ContactPersonTenantTenantIdHook>>
    >
>;
export type GetV1ContactPersonTenantTenantIdQueryError = ErrorType<void>;

export const useGetV1ContactPersonTenantTenantId = <
    TData = Awaited<
        ReturnType<ReturnType<typeof useGetV1ContactPersonTenantTenantIdHook>>
    >,
    TError = ErrorType<void>
>(
    tenantId: number,
    options?: {
        query?: UseQueryOptions<
            Awaited<
                ReturnType<
                    ReturnType<typeof useGetV1ContactPersonTenantTenantIdHook>
                >
            >,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1ContactPersonTenantTenantIdQueryOptions(
        tenantId,
        options
    );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const useGetV1ContactPersonApplicationHook = () => {
    const getV1ContactPersonApplication =
        useCustomAxios<ContactPersonResponse[]>();

    return () => {
        return getV1ContactPersonApplication({
            url: `/v1/ContactPerson/Application`,
            method: 'GET',
        });
    };
};

export const getGetV1ContactPersonApplicationQueryKey = () => {
    return [`/v1/ContactPerson/Application`] as const;
};

export const useGetV1ContactPersonApplicationQueryOptions = <
    TData = Awaited<
        ReturnType<ReturnType<typeof useGetV1ContactPersonApplicationHook>>
    >,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<
        Awaited<
            ReturnType<ReturnType<typeof useGetV1ContactPersonApplicationHook>>
        >,
        TError,
        TData
    >;
}) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getGetV1ContactPersonApplicationQueryKey();

    const getV1ContactPersonApplication =
        useGetV1ContactPersonApplicationHook();

    const queryFn: QueryFunction<
        Awaited<
            ReturnType<ReturnType<typeof useGetV1ContactPersonApplicationHook>>
        >
    > = () => getV1ContactPersonApplication();

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<
            ReturnType<ReturnType<typeof useGetV1ContactPersonApplicationHook>>
        >,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1ContactPersonApplicationQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetV1ContactPersonApplicationHook>>>
>;
export type GetV1ContactPersonApplicationQueryError = ErrorType<unknown>;

export const useGetV1ContactPersonApplication = <
    TData = Awaited<
        ReturnType<ReturnType<typeof useGetV1ContactPersonApplicationHook>>
    >,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<
        Awaited<
            ReturnType<ReturnType<typeof useGetV1ContactPersonApplicationHook>>
        >,
        TError,
        TData
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1ContactPersonApplicationQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Returns list of the claims for default authentication schema
 */
export const useGetV1IdentityUserInfoHook = () => {
    const getV1IdentityUserInfo = useCustomAxios<UserInfoResponse>();

    return () => {
        return getV1IdentityUserInfo({
            url: `/v1/Identity/UserInfo`,
            method: 'GET',
        });
    };
};

export const getGetV1IdentityUserInfoQueryKey = () => {
    return [`/v1/Identity/UserInfo`] as const;
};

export const useGetV1IdentityUserInfoQueryOptions = <
    TData = Awaited<
        ReturnType<ReturnType<typeof useGetV1IdentityUserInfoHook>>
    >,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1IdentityUserInfoHook>>>,
        TError,
        TData
    >;
}) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getGetV1IdentityUserInfoQueryKey();

    const getV1IdentityUserInfo = useGetV1IdentityUserInfoHook();

    const queryFn: QueryFunction<
        Awaited<ReturnType<ReturnType<typeof useGetV1IdentityUserInfoHook>>>
    > = () => getV1IdentityUserInfo();

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1IdentityUserInfoHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1IdentityUserInfoQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetV1IdentityUserInfoHook>>>
>;
export type GetV1IdentityUserInfoQueryError = ErrorType<void>;

/**
 * @summary Returns list of the claims for default authentication schema
 */
export const useGetV1IdentityUserInfo = <
    TData = Awaited<
        ReturnType<ReturnType<typeof useGetV1IdentityUserInfoHook>>
    >,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1IdentityUserInfoHook>>>,
        TError,
        TData
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1IdentityUserInfoQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const useGetV1PolicyPrivacyHook = () => {
    const getV1PolicyPrivacy = useCustomAxios<PolicyResponse>();

    return () => {
        return getV1PolicyPrivacy({ url: `/v1/Policy/Privacy`, method: 'GET' });
    };
};

export const getGetV1PolicyPrivacyQueryKey = () => {
    return [`/v1/Policy/Privacy`] as const;
};

export const useGetV1PolicyPrivacyQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetV1PolicyPrivacyHook>>>,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1PolicyPrivacyHook>>>,
        TError,
        TData
    >;
}) => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetV1PolicyPrivacyQueryKey();

    const getV1PolicyPrivacy = useGetV1PolicyPrivacyHook();

    const queryFn: QueryFunction<
        Awaited<ReturnType<ReturnType<typeof useGetV1PolicyPrivacyHook>>>
    > = () => getV1PolicyPrivacy();

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1PolicyPrivacyHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1PolicyPrivacyQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetV1PolicyPrivacyHook>>>
>;
export type GetV1PolicyPrivacyQueryError = ErrorType<void>;

export const useGetV1PolicyPrivacy = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetV1PolicyPrivacyHook>>>,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1PolicyPrivacyHook>>>,
        TError,
        TData
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1PolicyPrivacyQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const useGetV1PolicyCookiesHook = () => {
    const getV1PolicyCookies = useCustomAxios<PolicyResponse>();

    return () => {
        return getV1PolicyCookies({ url: `/v1/Policy/Cookies`, method: 'GET' });
    };
};

export const getGetV1PolicyCookiesQueryKey = () => {
    return [`/v1/Policy/Cookies`] as const;
};

export const useGetV1PolicyCookiesQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetV1PolicyCookiesHook>>>,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1PolicyCookiesHook>>>,
        TError,
        TData
    >;
}) => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetV1PolicyCookiesQueryKey();

    const getV1PolicyCookies = useGetV1PolicyCookiesHook();

    const queryFn: QueryFunction<
        Awaited<ReturnType<ReturnType<typeof useGetV1PolicyCookiesHook>>>
    > = () => getV1PolicyCookies();

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1PolicyCookiesHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1PolicyCookiesQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetV1PolicyCookiesHook>>>
>;
export type GetV1PolicyCookiesQueryError = ErrorType<void>;

export const useGetV1PolicyCookies = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetV1PolicyCookiesHook>>>,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1PolicyCookiesHook>>>,
        TError,
        TData
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1PolicyCookiesQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const useGetV1PolicyTermsHook = () => {
    const getV1PolicyTerms = useCustomAxios<PolicyResponse>();

    return () => {
        return getV1PolicyTerms({ url: `/v1/Policy/Terms`, method: 'GET' });
    };
};

export const getGetV1PolicyTermsQueryKey = () => {
    return [`/v1/Policy/Terms`] as const;
};

export const useGetV1PolicyTermsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetV1PolicyTermsHook>>>,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1PolicyTermsHook>>>,
        TError,
        TData
    >;
}) => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetV1PolicyTermsQueryKey();

    const getV1PolicyTerms = useGetV1PolicyTermsHook();

    const queryFn: QueryFunction<
        Awaited<ReturnType<ReturnType<typeof useGetV1PolicyTermsHook>>>
    > = () => getV1PolicyTerms();

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1PolicyTermsHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1PolicyTermsQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetV1PolicyTermsHook>>>
>;
export type GetV1PolicyTermsQueryError = ErrorType<void>;

export const useGetV1PolicyTerms = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetV1PolicyTermsHook>>>,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1PolicyTermsHook>>>,
        TError,
        TData
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1PolicyTermsQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const useGetV1PolicyTermsVersionHook = () => {
    const getV1PolicyTermsVersion = useCustomAxios<PolicyVersionResponse>();

    return () => {
        return getV1PolicyTermsVersion({
            url: `/v1/Policy/terms/version`,
            method: 'GET',
        });
    };
};

export const getGetV1PolicyTermsVersionQueryKey = () => {
    return [`/v1/Policy/terms/version`] as const;
};

export const useGetV1PolicyTermsVersionQueryOptions = <
    TData = Awaited<
        ReturnType<ReturnType<typeof useGetV1PolicyTermsVersionHook>>
    >,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1PolicyTermsVersionHook>>>,
        TError,
        TData
    >;
}) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getGetV1PolicyTermsVersionQueryKey();

    const getV1PolicyTermsVersion = useGetV1PolicyTermsVersionHook();

    const queryFn: QueryFunction<
        Awaited<ReturnType<ReturnType<typeof useGetV1PolicyTermsVersionHook>>>
    > = () => getV1PolicyTermsVersion();

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1PolicyTermsVersionHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1PolicyTermsVersionQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetV1PolicyTermsVersionHook>>>
>;
export type GetV1PolicyTermsVersionQueryError = ErrorType<void>;

export const useGetV1PolicyTermsVersion = <
    TData = Awaited<
        ReturnType<ReturnType<typeof useGetV1PolicyTermsVersionHook>>
    >,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1PolicyTermsVersionHook>>>,
        TError,
        TData
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1PolicyTermsVersionQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const useGetV1PolicyCookiesVersionHook = () => {
    const getV1PolicyCookiesVersion = useCustomAxios<PolicyVersionResponse>();

    return () => {
        return getV1PolicyCookiesVersion({
            url: `/v1/Policy/cookies/version`,
            method: 'GET',
        });
    };
};

export const getGetV1PolicyCookiesVersionQueryKey = () => {
    return [`/v1/Policy/cookies/version`] as const;
};

export const useGetV1PolicyCookiesVersionQueryOptions = <
    TData = Awaited<
        ReturnType<ReturnType<typeof useGetV1PolicyCookiesVersionHook>>
    >,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<
        Awaited<
            ReturnType<ReturnType<typeof useGetV1PolicyCookiesVersionHook>>
        >,
        TError,
        TData
    >;
}) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getGetV1PolicyCookiesVersionQueryKey();

    const getV1PolicyCookiesVersion = useGetV1PolicyCookiesVersionHook();

    const queryFn: QueryFunction<
        Awaited<ReturnType<ReturnType<typeof useGetV1PolicyCookiesVersionHook>>>
    > = () => getV1PolicyCookiesVersion();

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<
            ReturnType<ReturnType<typeof useGetV1PolicyCookiesVersionHook>>
        >,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1PolicyCookiesVersionQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetV1PolicyCookiesVersionHook>>>
>;
export type GetV1PolicyCookiesVersionQueryError = ErrorType<void>;

export const useGetV1PolicyCookiesVersion = <
    TData = Awaited<
        ReturnType<ReturnType<typeof useGetV1PolicyCookiesVersionHook>>
    >,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<
        Awaited<
            ReturnType<ReturnType<typeof useGetV1PolicyCookiesVersionHook>>
        >,
        TError,
        TData
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1PolicyCookiesVersionQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const useGetV1RoleAdminAllHook = () => {
    const getV1RoleAdminAll = useCustomAxios<RoleResponse[]>();

    return () => {
        return getV1RoleAdminAll({ url: `/v1/Role/Admin/All`, method: 'GET' });
    };
};

export const getGetV1RoleAdminAllQueryKey = () => {
    return [`/v1/Role/Admin/All`] as const;
};

export const useGetV1RoleAdminAllQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetV1RoleAdminAllHook>>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1RoleAdminAllHook>>>,
        TError,
        TData
    >;
}) => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetV1RoleAdminAllQueryKey();

    const getV1RoleAdminAll = useGetV1RoleAdminAllHook();

    const queryFn: QueryFunction<
        Awaited<ReturnType<ReturnType<typeof useGetV1RoleAdminAllHook>>>
    > = () => getV1RoleAdminAll();

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1RoleAdminAllHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1RoleAdminAllQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetV1RoleAdminAllHook>>>
>;
export type GetV1RoleAdminAllQueryError = ErrorType<unknown>;

export const useGetV1RoleAdminAll = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetV1RoleAdminAllHook>>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1RoleAdminAllHook>>>,
        TError,
        TData
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1RoleAdminAllQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Returns the list of total login count and most recent login for a user.
 */
export const useGetV1BusinessMetricsSuperAdminUserLoginsHook = () => {
    const getV1BusinessMetricsSuperAdminUserLogins =
        useCustomAxios<PaginatedUserLoginsResponse>();

    return (params?: GetV1BusinessMetricsSuperAdminUserLoginsParams) => {
        return getV1BusinessMetricsSuperAdminUserLogins({
            url: `/v1/BusinessMetrics/SuperAdmin/UserLogins`,
            method: 'GET',
            params,
        });
    };
};

export const getGetV1BusinessMetricsSuperAdminUserLoginsQueryKey = (
    params?: GetV1BusinessMetricsSuperAdminUserLoginsParams
) => {
    return [
        `/v1/BusinessMetrics/SuperAdmin/UserLogins`,
        ...(params ? [params] : []),
    ] as const;
};

export const useGetV1BusinessMetricsSuperAdminUserLoginsQueryOptions = <
    TData = Awaited<
        ReturnType<
            ReturnType<typeof useGetV1BusinessMetricsSuperAdminUserLoginsHook>
        >
    >,
    TError = ErrorType<unknown>
>(
    params?: GetV1BusinessMetricsSuperAdminUserLoginsParams,
    options?: {
        query?: UseQueryOptions<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof useGetV1BusinessMetricsSuperAdminUserLoginsHook
                    >
                >
            >,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ??
        getGetV1BusinessMetricsSuperAdminUserLoginsQueryKey(params);

    const getV1BusinessMetricsSuperAdminUserLogins =
        useGetV1BusinessMetricsSuperAdminUserLoginsHook();

    const queryFn: QueryFunction<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof useGetV1BusinessMetricsSuperAdminUserLoginsHook
                >
            >
        >
    > = () => getV1BusinessMetricsSuperAdminUserLogins(params);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof useGetV1BusinessMetricsSuperAdminUserLoginsHook
                >
            >
        >,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1BusinessMetricsSuperAdminUserLoginsQueryResult = NonNullable<
    Awaited<
        ReturnType<
            ReturnType<typeof useGetV1BusinessMetricsSuperAdminUserLoginsHook>
        >
    >
>;
export type GetV1BusinessMetricsSuperAdminUserLoginsQueryError =
    ErrorType<unknown>;

/**
 * @summary Returns the list of total login count and most recent login for a user.
 */
export const useGetV1BusinessMetricsSuperAdminUserLogins = <
    TData = Awaited<
        ReturnType<
            ReturnType<typeof useGetV1BusinessMetricsSuperAdminUserLoginsHook>
        >
    >,
    TError = ErrorType<unknown>
>(
    params?: GetV1BusinessMetricsSuperAdminUserLoginsParams,
    options?: {
        query?: UseQueryOptions<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof useGetV1BusinessMetricsSuperAdminUserLoginsHook
                    >
                >
            >,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions =
        useGetV1BusinessMetricsSuperAdminUserLoginsQueryOptions(
            params,
            options
        );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Returns the summary of user logins
 */
export const useGetV1BusinessMetricsSuperAdminUserLoginsSummaryContextHook =
    () => {
        const getV1BusinessMetricsSuperAdminUserLoginsSummaryContext =
            useCustomAxios<UserLoginsSummaryResponse>();

        return (context: string) => {
            return getV1BusinessMetricsSuperAdminUserLoginsSummaryContext({
                url: `/v1/BusinessMetrics/SuperAdmin/UserLoginsSummary/${context}`,
                method: 'GET',
            });
        };
    };

export const getGetV1BusinessMetricsSuperAdminUserLoginsSummaryContextQueryKey =
    (context: string) => {
        return [
            `/v1/BusinessMetrics/SuperAdmin/UserLoginsSummary/${context}`,
        ] as const;
    };

export const useGetV1BusinessMetricsSuperAdminUserLoginsSummaryContextQueryOptions =
    <
        TData = Awaited<
            ReturnType<
                ReturnType<
                    typeof useGetV1BusinessMetricsSuperAdminUserLoginsSummaryContextHook
                >
            >
        >,
        TError = ErrorType<void>
    >(
        context: string,
        options?: {
            query?: UseQueryOptions<
                Awaited<
                    ReturnType<
                        ReturnType<
                            typeof useGetV1BusinessMetricsSuperAdminUserLoginsSummaryContextHook
                        >
                    >
                >,
                TError,
                TData
            >;
        }
    ) => {
        const { query: queryOptions } = options ?? {};

        const queryKey =
            queryOptions?.queryKey ??
            getGetV1BusinessMetricsSuperAdminUserLoginsSummaryContextQueryKey(
                context
            );

        const getV1BusinessMetricsSuperAdminUserLoginsSummaryContext =
            useGetV1BusinessMetricsSuperAdminUserLoginsSummaryContextHook();

        const queryFn: QueryFunction<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof useGetV1BusinessMetricsSuperAdminUserLoginsSummaryContextHook
                    >
                >
            >
        > = () =>
            getV1BusinessMetricsSuperAdminUserLoginsSummaryContext(context);

        return {
            queryKey,
            queryFn,
            enabled: !!context,
            ...queryOptions,
        } as UseQueryOptions<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof useGetV1BusinessMetricsSuperAdminUserLoginsSummaryContextHook
                    >
                >
            >,
            TError,
            TData
        > & { queryKey: QueryKey };
    };

export type GetV1BusinessMetricsSuperAdminUserLoginsSummaryContextQueryResult =
    NonNullable<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof useGetV1BusinessMetricsSuperAdminUserLoginsSummaryContextHook
                >
            >
        >
    >;
export type GetV1BusinessMetricsSuperAdminUserLoginsSummaryContextQueryError =
    ErrorType<void>;

/**
 * @summary Returns the summary of user logins
 */
export const useGetV1BusinessMetricsSuperAdminUserLoginsSummaryContext = <
    TData = Awaited<
        ReturnType<
            ReturnType<
                typeof useGetV1BusinessMetricsSuperAdminUserLoginsSummaryContextHook
            >
        >
    >,
    TError = ErrorType<void>
>(
    context: string,
    options?: {
        query?: UseQueryOptions<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof useGetV1BusinessMetricsSuperAdminUserLoginsSummaryContextHook
                    >
                >
            >,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions =
        useGetV1BusinessMetricsSuperAdminUserLoginsSummaryContextQueryOptions(
            context,
            options
        );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Create a new application contact
 */
export const usePostV1ContactPersonSuperAdminApplicationHook = () => {
    const postV1ContactPersonSuperAdminApplication =
        useCustomAxios<ContactPersonResponse>();

    return (
        createApplicationContactRequest: CreateApplicationContactRequest
    ) => {
        return postV1ContactPersonSuperAdminApplication({
            url: `/v1/ContactPerson/SuperAdmin/Application`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: createApplicationContactRequest,
        });
    };
};

export const usePostV1ContactPersonSuperAdminApplicationMutationOptions = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePostV1ContactPersonSuperAdminApplicationHook
                >
            >
        >,
        TError,
        { data: CreateApplicationContactRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<
        ReturnType<
            ReturnType<typeof usePostV1ContactPersonSuperAdminApplicationHook>
        >
    >,
    TError,
    { data: CreateApplicationContactRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const postV1ContactPersonSuperAdminApplication =
        usePostV1ContactPersonSuperAdminApplicationHook();

    const mutationFn: MutationFunction<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePostV1ContactPersonSuperAdminApplicationHook
                >
            >
        >,
        { data: CreateApplicationContactRequest }
    > = (props) => {
        const { data } = props ?? {};

        return postV1ContactPersonSuperAdminApplication(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type PostV1ContactPersonSuperAdminApplicationMutationResult =
    NonNullable<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePostV1ContactPersonSuperAdminApplicationHook
                >
            >
        >
    >;
export type PostV1ContactPersonSuperAdminApplicationMutationBody =
    CreateApplicationContactRequest;
export type PostV1ContactPersonSuperAdminApplicationMutationError =
    ErrorType<void>;

/**
 * @summary Create a new application contact
 */
export const usePostV1ContactPersonSuperAdminApplication = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePostV1ContactPersonSuperAdminApplicationHook
                >
            >
        >,
        TError,
        { data: CreateApplicationContactRequest },
        TContext
    >;
}): UseMutationResult<
    Awaited<
        ReturnType<
            ReturnType<typeof usePostV1ContactPersonSuperAdminApplicationHook>
        >
    >,
    TError,
    { data: CreateApplicationContactRequest },
    TContext
> => {
    const mutationOptions =
        usePostV1ContactPersonSuperAdminApplicationMutationOptions(options);

    return useMutation(mutationOptions);
};

export const usePutV1PolicySuperAdminPolicyIdHook = () => {
    const putV1PolicySuperAdminPolicyId = useCustomAxios<PolicyResponse>();

    return (policyId: number, editPolicyRequest: EditPolicyRequest) => {
        return putV1PolicySuperAdminPolicyId({
            url: `/v1/Policy/SuperAdmin/${policyId}`,
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            data: editPolicyRequest,
        });
    };
};

export const usePutV1PolicySuperAdminPolicyIdMutationOptions = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<ReturnType<typeof usePutV1PolicySuperAdminPolicyIdHook>>
        >,
        TError,
        { policyId: number; data: EditPolicyRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<
        ReturnType<ReturnType<typeof usePutV1PolicySuperAdminPolicyIdHook>>
    >,
    TError,
    { policyId: number; data: EditPolicyRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const putV1PolicySuperAdminPolicyId =
        usePutV1PolicySuperAdminPolicyIdHook();

    const mutationFn: MutationFunction<
        Awaited<
            ReturnType<ReturnType<typeof usePutV1PolicySuperAdminPolicyIdHook>>
        >,
        { policyId: number; data: EditPolicyRequest }
    > = (props) => {
        const { policyId, data } = props ?? {};

        return putV1PolicySuperAdminPolicyId(policyId, data);
    };

    return { mutationFn, ...mutationOptions };
};

export type PutV1PolicySuperAdminPolicyIdMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof usePutV1PolicySuperAdminPolicyIdHook>>>
>;
export type PutV1PolicySuperAdminPolicyIdMutationBody = EditPolicyRequest;
export type PutV1PolicySuperAdminPolicyIdMutationError = ErrorType<void>;

export const usePutV1PolicySuperAdminPolicyId = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<ReturnType<typeof usePutV1PolicySuperAdminPolicyIdHook>>
        >,
        TError,
        { policyId: number; data: EditPolicyRequest },
        TContext
    >;
}): UseMutationResult<
    Awaited<
        ReturnType<ReturnType<typeof usePutV1PolicySuperAdminPolicyIdHook>>
    >,
    TError,
    { policyId: number; data: EditPolicyRequest },
    TContext
> => {
    const mutationOptions =
        usePutV1PolicySuperAdminPolicyIdMutationOptions(options);

    return useMutation(mutationOptions);
};

export const useGetV1TenantSuperAdminTenantIdHook = () => {
    const getV1TenantSuperAdminTenantId = useCustomAxios<TenantResponse>();

    return (tenantId: number) => {
        return getV1TenantSuperAdminTenantId({
            url: `/v1/Tenant/SuperAdmin/${tenantId}`,
            method: 'GET',
        });
    };
};

export const getGetV1TenantSuperAdminTenantIdQueryKey = (tenantId: number) => {
    return [`/v1/Tenant/SuperAdmin/${tenantId}`] as const;
};

export const useGetV1TenantSuperAdminTenantIdQueryOptions = <
    TData = Awaited<
        ReturnType<ReturnType<typeof useGetV1TenantSuperAdminTenantIdHook>>
    >,
    TError = ErrorType<void>
>(
    tenantId: number,
    options?: {
        query?: UseQueryOptions<
            Awaited<
                ReturnType<
                    ReturnType<typeof useGetV1TenantSuperAdminTenantIdHook>
                >
            >,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ??
        getGetV1TenantSuperAdminTenantIdQueryKey(tenantId);

    const getV1TenantSuperAdminTenantId =
        useGetV1TenantSuperAdminTenantIdHook();

    const queryFn: QueryFunction<
        Awaited<
            ReturnType<ReturnType<typeof useGetV1TenantSuperAdminTenantIdHook>>
        >
    > = () => getV1TenantSuperAdminTenantId(tenantId);

    return {
        queryKey,
        queryFn,
        enabled: !!tenantId,
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<
            ReturnType<ReturnType<typeof useGetV1TenantSuperAdminTenantIdHook>>
        >,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1TenantSuperAdminTenantIdQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetV1TenantSuperAdminTenantIdHook>>>
>;
export type GetV1TenantSuperAdminTenantIdQueryError = ErrorType<void>;

export const useGetV1TenantSuperAdminTenantId = <
    TData = Awaited<
        ReturnType<ReturnType<typeof useGetV1TenantSuperAdminTenantIdHook>>
    >,
    TError = ErrorType<void>
>(
    tenantId: number,
    options?: {
        query?: UseQueryOptions<
            Awaited<
                ReturnType<
                    ReturnType<typeof useGetV1TenantSuperAdminTenantIdHook>
                >
            >,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1TenantSuperAdminTenantIdQueryOptions(
        tenantId,
        options
    );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const usePutV1TenantSuperAdminTenantIdHook = () => {
    const putV1TenantSuperAdminTenantId = useCustomAxios<TenantResponse>();

    return (tenantId: number, editTenantRequest: EditTenantRequest) => {
        return putV1TenantSuperAdminTenantId({
            url: `/v1/Tenant/SuperAdmin/${tenantId}`,
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            data: editTenantRequest,
        });
    };
};

export const usePutV1TenantSuperAdminTenantIdMutationOptions = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<ReturnType<typeof usePutV1TenantSuperAdminTenantIdHook>>
        >,
        TError,
        { tenantId: number; data: EditTenantRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<
        ReturnType<ReturnType<typeof usePutV1TenantSuperAdminTenantIdHook>>
    >,
    TError,
    { tenantId: number; data: EditTenantRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const putV1TenantSuperAdminTenantId =
        usePutV1TenantSuperAdminTenantIdHook();

    const mutationFn: MutationFunction<
        Awaited<
            ReturnType<ReturnType<typeof usePutV1TenantSuperAdminTenantIdHook>>
        >,
        { tenantId: number; data: EditTenantRequest }
    > = (props) => {
        const { tenantId, data } = props ?? {};

        return putV1TenantSuperAdminTenantId(tenantId, data);
    };

    return { mutationFn, ...mutationOptions };
};

export type PutV1TenantSuperAdminTenantIdMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof usePutV1TenantSuperAdminTenantIdHook>>>
>;
export type PutV1TenantSuperAdminTenantIdMutationBody = EditTenantRequest;
export type PutV1TenantSuperAdminTenantIdMutationError = ErrorType<void>;

export const usePutV1TenantSuperAdminTenantId = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<ReturnType<typeof usePutV1TenantSuperAdminTenantIdHook>>
        >,
        TError,
        { tenantId: number; data: EditTenantRequest },
        TContext
    >;
}): UseMutationResult<
    Awaited<
        ReturnType<ReturnType<typeof usePutV1TenantSuperAdminTenantIdHook>>
    >,
    TError,
    { tenantId: number; data: EditTenantRequest },
    TContext
> => {
    const mutationOptions =
        usePutV1TenantSuperAdminTenantIdMutationOptions(options);

    return useMutation(mutationOptions);
};

export const useDeleteV1TenantSuperAdminTenantIdHook = () => {
    const deleteV1TenantSuperAdminTenantId = useCustomAxios<void>();

    return (tenantId: number) => {
        return deleteV1TenantSuperAdminTenantId({
            url: `/v1/Tenant/SuperAdmin/${tenantId}`,
            method: 'DELETE',
        });
    };
};

export const useDeleteV1TenantSuperAdminTenantIdMutationOptions = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<typeof useDeleteV1TenantSuperAdminTenantIdHook>
            >
        >,
        TError,
        { tenantId: number },
        TContext
    >;
}): UseMutationOptions<
    Awaited<
        ReturnType<ReturnType<typeof useDeleteV1TenantSuperAdminTenantIdHook>>
    >,
    TError,
    { tenantId: number },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const deleteV1TenantSuperAdminTenantId =
        useDeleteV1TenantSuperAdminTenantIdHook();

    const mutationFn: MutationFunction<
        Awaited<
            ReturnType<
                ReturnType<typeof useDeleteV1TenantSuperAdminTenantIdHook>
            >
        >,
        { tenantId: number }
    > = (props) => {
        const { tenantId } = props ?? {};

        return deleteV1TenantSuperAdminTenantId(tenantId);
    };

    return { mutationFn, ...mutationOptions };
};

export type DeleteV1TenantSuperAdminTenantIdMutationResult = NonNullable<
    Awaited<
        ReturnType<ReturnType<typeof useDeleteV1TenantSuperAdminTenantIdHook>>
    >
>;

export type DeleteV1TenantSuperAdminTenantIdMutationError = ErrorType<void>;

export const useDeleteV1TenantSuperAdminTenantId = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<typeof useDeleteV1TenantSuperAdminTenantIdHook>
            >
        >,
        TError,
        { tenantId: number },
        TContext
    >;
}): UseMutationResult<
    Awaited<
        ReturnType<ReturnType<typeof useDeleteV1TenantSuperAdminTenantIdHook>>
    >,
    TError,
    { tenantId: number },
    TContext
> => {
    const mutationOptions =
        useDeleteV1TenantSuperAdminTenantIdMutationOptions(options);

    return useMutation(mutationOptions);
};

export const useGetV1TenantSuperAdminHook = () => {
    const getV1TenantSuperAdmin = useCustomAxios<PaginatedTenantResponse>();

    return (params?: GetV1TenantSuperAdminParams) => {
        return getV1TenantSuperAdmin({
            url: `/v1/Tenant/SuperAdmin`,
            method: 'GET',
            params,
        });
    };
};

export const getGetV1TenantSuperAdminQueryKey = (
    params?: GetV1TenantSuperAdminParams
) => {
    return [`/v1/Tenant/SuperAdmin`, ...(params ? [params] : [])] as const;
};

export const useGetV1TenantSuperAdminQueryOptions = <
    TData = Awaited<
        ReturnType<ReturnType<typeof useGetV1TenantSuperAdminHook>>
    >,
    TError = ErrorType<void>
>(
    params?: GetV1TenantSuperAdminParams,
    options?: {
        query?: UseQueryOptions<
            Awaited<
                ReturnType<ReturnType<typeof useGetV1TenantSuperAdminHook>>
            >,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getGetV1TenantSuperAdminQueryKey(params);

    const getV1TenantSuperAdmin = useGetV1TenantSuperAdminHook();

    const queryFn: QueryFunction<
        Awaited<ReturnType<ReturnType<typeof useGetV1TenantSuperAdminHook>>>
    > = () => getV1TenantSuperAdmin(params);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1TenantSuperAdminHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1TenantSuperAdminQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetV1TenantSuperAdminHook>>>
>;
export type GetV1TenantSuperAdminQueryError = ErrorType<void>;

export const useGetV1TenantSuperAdmin = <
    TData = Awaited<
        ReturnType<ReturnType<typeof useGetV1TenantSuperAdminHook>>
    >,
    TError = ErrorType<void>
>(
    params?: GetV1TenantSuperAdminParams,
    options?: {
        query?: UseQueryOptions<
            Awaited<
                ReturnType<ReturnType<typeof useGetV1TenantSuperAdminHook>>
            >,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1TenantSuperAdminQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const usePostV1TenantSuperAdminHook = () => {
    const postV1TenantSuperAdmin = useCustomAxios<TenantResponse>();

    return (createTenantRequest: CreateTenantRequest) => {
        return postV1TenantSuperAdmin({
            url: `/v1/Tenant/SuperAdmin`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: createTenantRequest,
        });
    };
};

export const usePostV1TenantSuperAdminMutationOptions = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof usePostV1TenantSuperAdminHook>>>,
        TError,
        { data: CreateTenantRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostV1TenantSuperAdminHook>>>,
    TError,
    { data: CreateTenantRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const postV1TenantSuperAdmin = usePostV1TenantSuperAdminHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof usePostV1TenantSuperAdminHook>>>,
        { data: CreateTenantRequest }
    > = (props) => {
        const { data } = props ?? {};

        return postV1TenantSuperAdmin(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type PostV1TenantSuperAdminMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof usePostV1TenantSuperAdminHook>>>
>;
export type PostV1TenantSuperAdminMutationBody = CreateTenantRequest;
export type PostV1TenantSuperAdminMutationError = ErrorType<void>;

export const usePostV1TenantSuperAdmin = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof usePostV1TenantSuperAdminHook>>>,
        TError,
        { data: CreateTenantRequest },
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof usePostV1TenantSuperAdminHook>>>,
    TError,
    { data: CreateTenantRequest },
    TContext
> => {
    const mutationOptions = usePostV1TenantSuperAdminMutationOptions(options);

    return useMutation(mutationOptions);
};

export const usePutV1TenantSuperAdminRestoreTenantIdHook = () => {
    const putV1TenantSuperAdminRestoreTenantId = useCustomAxios<void>();

    return (
        tenantId: number,
        params?: PutV1TenantSuperAdminRestoreTenantIdParams
    ) => {
        return putV1TenantSuperAdminRestoreTenantId({
            url: `/v1/Tenant/SuperAdmin/restore/${tenantId}`,
            method: 'PUT',
            params,
        });
    };
};

export const usePutV1TenantSuperAdminRestoreTenantIdMutationOptions = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<typeof usePutV1TenantSuperAdminRestoreTenantIdHook>
            >
        >,
        TError,
        {
            tenantId: number;
            params?: PutV1TenantSuperAdminRestoreTenantIdParams;
        },
        TContext
    >;
}): UseMutationOptions<
    Awaited<
        ReturnType<
            ReturnType<typeof usePutV1TenantSuperAdminRestoreTenantIdHook>
        >
    >,
    TError,
    { tenantId: number; params?: PutV1TenantSuperAdminRestoreTenantIdParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const putV1TenantSuperAdminRestoreTenantId =
        usePutV1TenantSuperAdminRestoreTenantIdHook();

    const mutationFn: MutationFunction<
        Awaited<
            ReturnType<
                ReturnType<typeof usePutV1TenantSuperAdminRestoreTenantIdHook>
            >
        >,
        {
            tenantId: number;
            params?: PutV1TenantSuperAdminRestoreTenantIdParams;
        }
    > = (props) => {
        const { tenantId, params } = props ?? {};

        return putV1TenantSuperAdminRestoreTenantId(tenantId, params);
    };

    return { mutationFn, ...mutationOptions };
};

export type PutV1TenantSuperAdminRestoreTenantIdMutationResult = NonNullable<
    Awaited<
        ReturnType<
            ReturnType<typeof usePutV1TenantSuperAdminRestoreTenantIdHook>
        >
    >
>;

export type PutV1TenantSuperAdminRestoreTenantIdMutationError = ErrorType<void>;

export const usePutV1TenantSuperAdminRestoreTenantId = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<typeof usePutV1TenantSuperAdminRestoreTenantIdHook>
            >
        >,
        TError,
        {
            tenantId: number;
            params?: PutV1TenantSuperAdminRestoreTenantIdParams;
        },
        TContext
    >;
}): UseMutationResult<
    Awaited<
        ReturnType<
            ReturnType<typeof usePutV1TenantSuperAdminRestoreTenantIdHook>
        >
    >,
    TError,
    { tenantId: number; params?: PutV1TenantSuperAdminRestoreTenantIdParams },
    TContext
> => {
    const mutationOptions =
        usePutV1TenantSuperAdminRestoreTenantIdMutationOptions(options);

    return useMutation(mutationOptions);
};

export const useGetV1TenantHook = () => {
    const getV1Tenant = useCustomAxios<TenantResponse>();

    return () => {
        return getV1Tenant({ url: `/v1/Tenant`, method: 'GET' });
    };
};

export const getGetV1TenantQueryKey = () => {
    return [`/v1/Tenant`] as const;
};

export const useGetV1TenantQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetV1TenantHook>>>,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1TenantHook>>>,
        TError,
        TData
    >;
}) => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetV1TenantQueryKey();

    const getV1Tenant = useGetV1TenantHook();

    const queryFn: QueryFunction<
        Awaited<ReturnType<ReturnType<typeof useGetV1TenantHook>>>
    > = () => getV1Tenant();

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1TenantHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1TenantQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetV1TenantHook>>>
>;
export type GetV1TenantQueryError = ErrorType<void>;

export const useGetV1Tenant = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetV1TenantHook>>>,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1TenantHook>>>,
        TError,
        TData
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1TenantQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Returns a list of all users for the requesting user's tenant
Super Admins are able to specify a tenantId from which the users are retrieved
 */
export const useGetV1UserAdminHook = () => {
    const getV1UserAdmin = useCustomAxios<PaginatedUserResponse>();

    return (params?: GetV1UserAdminParams) => {
        return getV1UserAdmin({ url: `/v1/User/Admin`, method: 'GET', params });
    };
};

export const getGetV1UserAdminQueryKey = (params?: GetV1UserAdminParams) => {
    return [`/v1/User/Admin`, ...(params ? [params] : [])] as const;
};

export const useGetV1UserAdminQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetV1UserAdminHook>>>,
    TError = ErrorType<void>
>(
    params?: GetV1UserAdminParams,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<ReturnType<typeof useGetV1UserAdminHook>>>,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getGetV1UserAdminQueryKey(params);

    const getV1UserAdmin = useGetV1UserAdminHook();

    const queryFn: QueryFunction<
        Awaited<ReturnType<ReturnType<typeof useGetV1UserAdminHook>>>
    > = () => getV1UserAdmin(params);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1UserAdminHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1UserAdminQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetV1UserAdminHook>>>
>;
export type GetV1UserAdminQueryError = ErrorType<void>;

/**
 * @summary Returns a list of all users for the requesting user's tenant
Super Admins are able to specify a tenantId from which the users are retrieved
 */
export const useGetV1UserAdmin = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetV1UserAdminHook>>>,
    TError = ErrorType<void>
>(
    params?: GetV1UserAdminParams,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<ReturnType<typeof useGetV1UserAdminHook>>>,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1UserAdminQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Create a new user
 */
export const usePostV1UserAdminHook = () => {
    const postV1UserAdmin = useCustomAxios<UserResponse>();

    return (createUserRequest: CreateUserRequest) => {
        return postV1UserAdmin({
            url: `/v1/User/Admin`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: createUserRequest,
        });
    };
};

export const usePostV1UserAdminMutationOptions = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof usePostV1UserAdminHook>>>,
        TError,
        { data: CreateUserRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostV1UserAdminHook>>>,
    TError,
    { data: CreateUserRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const postV1UserAdmin = usePostV1UserAdminHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof usePostV1UserAdminHook>>>,
        { data: CreateUserRequest }
    > = (props) => {
        const { data } = props ?? {};

        return postV1UserAdmin(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type PostV1UserAdminMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof usePostV1UserAdminHook>>>
>;
export type PostV1UserAdminMutationBody = CreateUserRequest;
export type PostV1UserAdminMutationError = ErrorType<void>;

/**
 * @summary Create a new user
 */
export const usePostV1UserAdmin = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof usePostV1UserAdminHook>>>,
        TError,
        { data: CreateUserRequest },
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof usePostV1UserAdminHook>>>,
    TError,
    { data: CreateUserRequest },
    TContext
> => {
    const mutationOptions = usePostV1UserAdminMutationOptions(options);

    return useMutation(mutationOptions);
};

/**
 * @summary Edit an existing user
 */
export const usePutV1UserAdminUserIdHook = () => {
    const putV1UserAdminUserId = useCustomAxios<UserResponse>();

    return (userId: number, editUserRequest: EditUserRequest) => {
        return putV1UserAdminUserId({
            url: `/v1/User/Admin/${userId}`,
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            data: editUserRequest,
        });
    };
};

export const usePutV1UserAdminUserIdMutationOptions = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof usePutV1UserAdminUserIdHook>>>,
        TError,
        { userId: number; data: EditUserRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutV1UserAdminUserIdHook>>>,
    TError,
    { userId: number; data: EditUserRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const putV1UserAdminUserId = usePutV1UserAdminUserIdHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof usePutV1UserAdminUserIdHook>>>,
        { userId: number; data: EditUserRequest }
    > = (props) => {
        const { userId, data } = props ?? {};

        return putV1UserAdminUserId(userId, data);
    };

    return { mutationFn, ...mutationOptions };
};

export type PutV1UserAdminUserIdMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof usePutV1UserAdminUserIdHook>>>
>;
export type PutV1UserAdminUserIdMutationBody = EditUserRequest;
export type PutV1UserAdminUserIdMutationError = ErrorType<void>;

/**
 * @summary Edit an existing user
 */
export const usePutV1UserAdminUserId = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof usePutV1UserAdminUserIdHook>>>,
        TError,
        { userId: number; data: EditUserRequest },
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof usePutV1UserAdminUserIdHook>>>,
    TError,
    { userId: number; data: EditUserRequest },
    TContext
> => {
    const mutationOptions = usePutV1UserAdminUserIdMutationOptions(options);

    return useMutation(mutationOptions);
};

/**
 * @summary Soft delete an existing user
 */
export const useDeleteV1UserAdminUserIdHook = () => {
    const deleteV1UserAdminUserId = useCustomAxios<void>();

    return (userId: number) => {
        return deleteV1UserAdminUserId({
            url: `/v1/User/Admin/${userId}`,
            method: 'DELETE',
        });
    };
};

export const useDeleteV1UserAdminUserIdMutationOptions = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteV1UserAdminUserIdHook>>>,
        TError,
        { userId: number },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteV1UserAdminUserIdHook>>>,
    TError,
    { userId: number },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const deleteV1UserAdminUserId = useDeleteV1UserAdminUserIdHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useDeleteV1UserAdminUserIdHook>>>,
        { userId: number }
    > = (props) => {
        const { userId } = props ?? {};

        return deleteV1UserAdminUserId(userId);
    };

    return { mutationFn, ...mutationOptions };
};

export type DeleteV1UserAdminUserIdMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useDeleteV1UserAdminUserIdHook>>>
>;

export type DeleteV1UserAdminUserIdMutationError = ErrorType<void>;

/**
 * @summary Soft delete an existing user
 */
export const useDeleteV1UserAdminUserId = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteV1UserAdminUserIdHook>>>,
        TError,
        { userId: number },
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useDeleteV1UserAdminUserIdHook>>>,
    TError,
    { userId: number },
    TContext
> => {
    const mutationOptions = useDeleteV1UserAdminUserIdMutationOptions(options);

    return useMutation(mutationOptions);
};

/**
 * @summary Restore deleted user
 */
export const usePutV1UserAdminRestoreUserIdHook = () => {
    const putV1UserAdminRestoreUserId = useCustomAxios<void>();

    return (userId: number) => {
        return putV1UserAdminRestoreUserId({
            url: `/v1/User/Admin/restore/${userId}`,
            method: 'PUT',
        });
    };
};

export const usePutV1UserAdminRestoreUserIdMutationOptions = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<ReturnType<typeof usePutV1UserAdminRestoreUserIdHook>>
        >,
        TError,
        { userId: number },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutV1UserAdminRestoreUserIdHook>>>,
    TError,
    { userId: number },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const putV1UserAdminRestoreUserId = usePutV1UserAdminRestoreUserIdHook();

    const mutationFn: MutationFunction<
        Awaited<
            ReturnType<ReturnType<typeof usePutV1UserAdminRestoreUserIdHook>>
        >,
        { userId: number }
    > = (props) => {
        const { userId } = props ?? {};

        return putV1UserAdminRestoreUserId(userId);
    };

    return { mutationFn, ...mutationOptions };
};

export type PutV1UserAdminRestoreUserIdMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof usePutV1UserAdminRestoreUserIdHook>>>
>;

export type PutV1UserAdminRestoreUserIdMutationError = ErrorType<void>;

/**
 * @summary Restore deleted user
 */
export const usePutV1UserAdminRestoreUserId = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<ReturnType<typeof usePutV1UserAdminRestoreUserIdHook>>
        >,
        TError,
        { userId: number },
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof usePutV1UserAdminRestoreUserIdHook>>>,
    TError,
    { userId: number },
    TContext
> => {
    const mutationOptions =
        usePutV1UserAdminRestoreUserIdMutationOptions(options);

    return useMutation(mutationOptions);
};

export const useGetV1UserPreferencesHook = () => {
    const getV1UserPreferences = useCustomAxios<UserPreferencesResponse>();

    return () => {
        return getV1UserPreferences({
            url: `/v1/User/Preferences`,
            method: 'GET',
        });
    };
};

export const getGetV1UserPreferencesQueryKey = () => {
    return [`/v1/User/Preferences`] as const;
};

export const useGetV1UserPreferencesQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetV1UserPreferencesHook>>>,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1UserPreferencesHook>>>,
        TError,
        TData
    >;
}) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getGetV1UserPreferencesQueryKey();

    const getV1UserPreferences = useGetV1UserPreferencesHook();

    const queryFn: QueryFunction<
        Awaited<ReturnType<ReturnType<typeof useGetV1UserPreferencesHook>>>
    > = () => getV1UserPreferences();

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1UserPreferencesHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1UserPreferencesQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetV1UserPreferencesHook>>>
>;
export type GetV1UserPreferencesQueryError = ErrorType<void>;

export const useGetV1UserPreferences = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetV1UserPreferencesHook>>>,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1UserPreferencesHook>>>,
        TError,
        TData
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1UserPreferencesQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const usePutV1UserPreferencesHook = () => {
    const putV1UserPreferences = useCustomAxios<UserPreferencesResponse>();

    return (userPreferencesRequest: UserPreferencesRequest) => {
        return putV1UserPreferences({
            url: `/v1/User/Preferences`,
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            data: userPreferencesRequest,
        });
    };
};

export const usePutV1UserPreferencesMutationOptions = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof usePutV1UserPreferencesHook>>>,
        TError,
        { data: UserPreferencesRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutV1UserPreferencesHook>>>,
    TError,
    { data: UserPreferencesRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const putV1UserPreferences = usePutV1UserPreferencesHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof usePutV1UserPreferencesHook>>>,
        { data: UserPreferencesRequest }
    > = (props) => {
        const { data } = props ?? {};

        return putV1UserPreferences(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type PutV1UserPreferencesMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof usePutV1UserPreferencesHook>>>
>;
export type PutV1UserPreferencesMutationBody = UserPreferencesRequest;
export type PutV1UserPreferencesMutationError = ErrorType<void>;

export const usePutV1UserPreferences = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof usePutV1UserPreferencesHook>>>,
        TError,
        { data: UserPreferencesRequest },
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof usePutV1UserPreferencesHook>>>,
    TError,
    { data: UserPreferencesRequest },
    TContext
> => {
    const mutationOptions = usePutV1UserPreferencesMutationOptions(options);

    return useMutation(mutationOptions);
};

export const useGetV1VersionHook = () => {
    const getV1Version = useCustomAxios<ApplicationVersionResponse>();

    return () => {
        return getV1Version({ url: `/v1/Version`, method: 'GET' });
    };
};

export const getGetV1VersionQueryKey = () => {
    return [`/v1/Version`] as const;
};

export const useGetV1VersionQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetV1VersionHook>>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1VersionHook>>>,
        TError,
        TData
    >;
}) => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetV1VersionQueryKey();

    const getV1Version = useGetV1VersionHook();

    const queryFn: QueryFunction<
        Awaited<ReturnType<ReturnType<typeof useGetV1VersionHook>>>
    > = () => getV1Version();

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1VersionHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1VersionQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetV1VersionHook>>>
>;
export type GetV1VersionQueryError = ErrorType<unknown>;

export const useGetV1Version = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetV1VersionHook>>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1VersionHook>>>,
        TError,
        TData
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1VersionQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};
