import React, { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { Button } from '@appkit4/react-components/button';
import useLogout from '../../auth/useLogout';
import { AppRoutes } from '../../Constants';
import LoggedOutImg from '../../assets/illustrations/LoggedOut.svg';
import useLocale from '../../locale/useLocale';
import useTitle from '../../helpers/useTitle';

const Logout = () => {
    const logout = useLogout();
    const auth = useAuth();
    const { l } = useLocale();
    useTitle(l('_loggingOutTitleCase'));

    useEffect(() => {
        if (auth.isAuthenticated) {
            logout();
        }
    }, [auth.isAuthenticated]);

    return (
        <div className="d-flex flex-grow-1 align-items-center flex-column justify-content-center text-center w-100">
            <img
                src={LoggedOutImg as string}
                alt={l('_laptopNotWorking')}
                style={{ width: '50%' }}
            />
            <h1 className="px-2 py-1 mt-2 mb-0">
                {auth.isAuthenticated && <>{l('_loggingOutTitleCase')}</>}
                {!auth.isAuthenticated && (
                    <>{l('_logoutSuccessfulTitleCase')}</>
                )}
            </h1>
            {!auth.isAuthenticated && (
                <>
                    <p>{l('_weHopeYouComeBackSoon')}</p>
                    <div className="d-flex">
                        <Button
                            onClick={() => {
                                // cannot use navigate as the auth requires the page to refresh
                                window.location.replace(AppRoutes.LandingPage);
                            }}
                        >
                            <i className="Appkit4-icon icon-home-fill" />
                            {l('_home')}
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};

export default Logout;
