import { Modal } from '@appkit4/react-components/modal';
import CreateTenantForm, { FormValues } from './CreateTenantForm';
import useGenericErrorToast from '../../../../../helpers/useGenericErrorToast';
import useToast from '../../../../../helpers/useToast';
import {
    PostV1TenantSuperAdminMutationError,
    usePostV1TenantSuperAdmin,
} from '../../../../../api/generated/endpoint';
import useLocale from '../../../../../locale/useLocale';
import ModalContainer from '../../../../ModalContainer';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../../../Constants';
import { TenantResponse } from '../../../../../api/generated/models';

interface CreateTenantModalProps {
    isVisible: boolean;
    setIsVisible: (display: boolean) => void;
}

const CreateTenantModal = (props: CreateTenantModalProps) => {
    const showToast = useToast();
    const { l } = useLocale();
    const navigate = useNavigate();
    const showGenericErrorToast = useGenericErrorToast();
    const createNewTenant = usePostV1TenantSuperAdmin();

    const postNewTenantRequest = (values: FormValues) => {
        createNewTenant.mutate(
            {
                data: {
                    name: values.name,
                },
            },
            {
                onSuccess: (response: TenantResponse) => {
                    showToast(l('_success'), l('_newTenantCreated'), 'success');
                    props.setIsVisible(false);
                    navigate(`${AppRoutes.Tenant}/${response.id}`);
                },
                onError: (error: PostV1TenantSuperAdminMutationError) => {
                    showGenericErrorToast(error);
                    props.setIsVisible(false);
                },
            }
        );
    };

    return (
        <>
            {props.isVisible && (
                <ModalContainer isVisible={props.isVisible}>
                    <Modal
                        visible
                        closable
                        closeAriaLabel={l('_close')}
                        title={l('_createNewTenant')}
                        aria-label={l('_createANewTenant')}
                        modalStyle={{
                            width: '50vw',
                            maxWidth: '500px',
                            minWidth: '250px',
                        }}
                        footerStyle={{ marginTop: '1rem' }}
                        closeOnPressEscape
                        onCancel={() => props.setIsVisible(false)}
                    >
                        <CreateTenantForm submitFunc={postNewTenantRequest} />
                    </Modal>
                </ModalContainer>
            )}
        </>
    );
};

export default CreateTenantModal;
