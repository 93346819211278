import { Table, Column } from '@appkit4/react-components/table';
import './BusinessMetrics.scss';
import { Pagination } from '@appkit4/react-components/pagination';
import { Search } from '@appkit4/react-components/search';
import { useState } from 'react';
import DonutPieChartTemplate from '../../components/pages/metrics/businessMetrics/superAdmin/DonutPieChart';
import CalendarTemplate from '../../components/pages/metrics/businessMetrics/superAdmin/Calendar';
import useGenericErrorToast from '../../helpers/useGenericErrorToast';
import {
    useGetV1BusinessMetricsSuperAdminUserLogins,
    useGetV1BusinessMetricsSuperAdminUserLoginsSummaryContext,
} from '../../api/generated/endpoint';
import { SummaryItemResponse } from '../../api/generated/models';
import usePaginationControl from '../../helpers/usePaginationControl';
import useLocale from '../../locale/useLocale';
import useTitle from '../../helpers/useTitle';

interface CalendarChartDataItem {
    day: string;
    value: number;
}

const BusinessMetrics = () => {
    const { l } = useLocale();
    useTitle(l('_businessMetricsTitleCase'));

    const pageSize = 20;
    const [searchString, setSearchString] = useState<string>('');
    const [sortString, setSortString] = useState<string | undefined>(undefined);
    const [pageNum, setPageNum] = useState<number>(1);
    let typingTimer: NodeJS.Timeout; //timer identifier
    const doneTypingIntervalms = 1000; //time in ms
    const skip = (pageNum - 1) * pageSize;
    const take = pageSize;

    const { data: allUsersLoginsBusinessMetrics } =
        useGetV1BusinessMetricsSuperAdminUserLogins(
            {
                includeInactive: true,
            },
            {
                query: {
                    onError: useGenericErrorToast,
                },
            }
        );

    const {
        data: paginatedUserLoginsBusinessMetrics,
        isLoading: loadingUserLoginBusinessMetrics,
    } = useGetV1BusinessMetricsSuperAdminUserLogins(
        { skip, take, searchString, sortString },
        {
            query: {
                onError: useGenericErrorToast,
            },
        }
    );

    const { data: totalUserLoginsSummary } =
        useGetV1BusinessMetricsSuperAdminUserLoginsSummaryContext('total', {
            query: {
                onError: useGenericErrorToast,
            },
        });

    const { data: uniqueUserLoginsSummary } =
        useGetV1BusinessMetricsSuperAdminUserLoginsSummaryContext('unique', {
            query: {
                onError: useGenericErrorToast,
            },
        });

    const { data: deletedUserLoginsSummary } =
        useGetV1BusinessMetricsSuperAdminUserLoginsSummaryContext('deleted', {
            query: {
                onError: useGenericErrorToast,
            },
        });

    /* prevent current page number being greater than the largest */
    usePaginationControl(
        { totalCount: paginatedUserLoginsBusinessMetrics?.totalUserCount },
        pageSize,
        setPageNum,
        pageNum
    );

    const formattedData = paginatedUserLoginsBusinessMetrics?.usersLogin.map(
        (user) => {
            const firstLogin = user.firstLogin;
            const firstLoginFormatted = firstLogin
                ? new Date(firstLogin).toLocaleDateString('en-UK', {
                      day: '2-digit',
                      month: 'short',
                      year: 'numeric',
                  })
                : '';

            const lastLogin = user.lastLogin;
            const lastLoginFormatted = lastLogin
                ? new Date(lastLogin).toLocaleDateString('en-UK', {
                      day: '2-digit',
                      month: 'short',
                      year: 'numeric',
                  })
                : '';

            return {
                ...user,
                firstLoginFormatted,
                lastLoginFormatted,
            };
        }
    );

    const calendarData: CalendarChartDataItem[] =
        allUsersLoginsBusinessMetrics?.usersLogin
            .flatMap((user) => user.logins)
            .map((date) => date?.toString().split('T')[0])
            .filter((date): date is string => date !== undefined)
            .reduce<CalendarChartDataItem[]>((acc, date: string) => {
                const existing = acc.find((item) => item.day === date);
                if (existing) {
                    existing.value++;
                } else {
                    acc.push({ day: date, value: 1 });
                }
                return acc;
            }, []) || [];

    const onTableSort = (sortKey: string, sortingPhase: number) => {
        if (sortingPhase === 0) {
            setSortString(undefined);
        } else {
            setSortString(`${sortKey} ${sortingPhase == 1 ? 'ASC' : 'DESC'}`);

            // sortingPhase == 1 - ascending
            // sortingPhase == 2 - descending
            // sortingPhase == 0 - disable
        }
    };

    const formatDataForPieChart = (summaryItems?: SummaryItemResponse[]) => {
        if (summaryItems == undefined) {
            return [];
        } else {
            return summaryItems.map((x) => ({
                id: x.label,
                ...x,
            }));
        }
    };

    return (
        <>
            <h1 aria-label="business-metrics-title">
                {l('_businessMetricsTitleCase')}
            </h1>
            <div>
                <div className="row kpi">
                    <div className="col-md-4">
                        <div className="panel-container">
                            <h3>{l('_totalSessions')}</h3>
                            <h3>{totalUserLoginsSummary?.count}</h3>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="panel-container">
                            <h3>{l('_totalUsers')}</h3>
                            <h3>{uniqueUserLoginsSummary?.count}</h3>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="panel-container">
                            <h3>{l('_deletedUsers')}</h3>
                            <h3>{deletedUserLoginsSummary?.count}</h3>
                        </div>
                    </div>
                </div>
                <div className="row charts">
                    <div className="col-md-4">
                        <div className="panel-container">
                            <h3>{l('_totalLogins')}</h3>
                            <h4>{l('_gradeBreakdown')}</h4>
                            <div className="responsive-pie">
                                <DonutPieChartTemplate
                                    data={formatDataForPieChart(
                                        totalUserLoginsSummary?.grade
                                    )}
                                />
                            </div>
                            <h4>{l('_loSBreakdown')}</h4>
                            <div className="responsive-pie">
                                <DonutPieChartTemplate
                                    data={formatDataForPieChart(
                                        totalUserLoginsSummary?.los
                                    )}
                                />
                            </div>
                            <h4>{l('_territoryBreakdown')}</h4>
                            <div className="responsive-pie">
                                <DonutPieChartTemplate
                                    data={formatDataForPieChart(
                                        totalUserLoginsSummary?.territory
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="panel-container">
                            <h3>{l('_uniqueLogins')}</h3>
                            <h4>{l('_gradeBreakdown')}</h4>
                            <div className="responsive-pie">
                                <DonutPieChartTemplate
                                    data={formatDataForPieChart(
                                        uniqueUserLoginsSummary?.grade
                                    )}
                                />
                            </div>
                            <h4>{l('_loSBreakdown')}</h4>
                            <div className="responsive-pie">
                                <DonutPieChartTemplate
                                    data={formatDataForPieChart(
                                        uniqueUserLoginsSummary?.los
                                    )}
                                />
                            </div>
                            <h4>{l('_territoryBreakdown')}</h4>
                            <div className="responsive-pie">
                                <DonutPieChartTemplate
                                    data={formatDataForPieChart(
                                        uniqueUserLoginsSummary?.territory
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="panel-container">
                            <h3>{l('_deletedUsers')}</h3>
                            <h4>{l('_gradeBreakdown')}</h4>
                            <div className="responsive-pie">
                                <DonutPieChartTemplate
                                    data={formatDataForPieChart(
                                        deletedUserLoginsSummary?.grade
                                    )}
                                />
                            </div>
                            <h4>{l('_loSBreakdown')}</h4>
                            <div className="responsive-pie">
                                <DonutPieChartTemplate
                                    data={formatDataForPieChart(
                                        deletedUserLoginsSummary?.los
                                    )}
                                />
                            </div>
                            <h4>{l('_territoryBreakdown')}</h4>
                            <div className="responsive-pie">
                                <DonutPieChartTemplate
                                    data={formatDataForPieChart(
                                        deletedUserLoginsSummary?.territory
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <p>{l('_gradeMetricsNote')}</p>
                <div className="row calendar">
                    <div className="col-md-12">
                        <div className="panel-container">
                            <h3>{l('_loginCalendar')}</h3>
                            <div className="row responsive-calendar">
                                <CalendarTemplate data={calendarData} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row search-bar">
                <h3>{l('_allActiveUsers')}</h3>
                <div className="col">
                    <Search
                        searchType="primary"
                        onSearch={(searchString) =>
                            setSearchString(searchString)
                        }
                        onChange={(search: string, event) => {
                            clearTimeout(typingTimer);
                            if (!search) {
                                setSearchString('');
                            } else {
                                typingTimer = setTimeout(
                                    () =>
                                        // simulate pressing enter on the search box
                                        // so the onSearch method handles all the actual searching
                                        event.target.dispatchEvent(
                                            new KeyboardEvent('keydown', {
                                                code: 'Enter',
                                                key: 'Enter',
                                                charCode: 13,
                                                keyCode: 13,
                                                view: window,
                                                bubbles: true,
                                            })
                                        ),
                                    doneTypingIntervalms
                                );
                            }
                        }}
                        onClear={() => setSearchString('')}
                        className="m-0 p-0"
                    />
                </div>
                <div className="col"></div>
            </div>
            <Table
                className="ap-mt-spacing-4 business-metrics-user-records-table-container"
                originalData={formattedData ?? []}
                condensed
                striped
                hasTitle
                onSort={onTableSort}
                disableDefaultSort
            >
                <Column field="forenames" sortKey="forenames">
                    {l('_forename(s)')}
                </Column>
                <Column field="surname" sortKey="surname">
                    {l('_surname')}
                </Column>
                <Column field="email" sortKey="email">
                    {l('_email')}
                </Column>
                <Column field="loS" sortKey="loS">
                    {l('_los')}
                </Column>
                <Column field="territory" sortKey="territory">
                    {l('_territory')}
                </Column>
                <Column field="loginCount" sortKey="loginCount">
                    {l('_loginCount')}
                </Column>
                <Column field="firstLoginFormatted" sortKey="firstLogin">
                    {l('_firstLogin')}
                </Column>
                <Column field="lastLoginFormatted" sortKey="lastLogin">
                    {l('_lastlogin')}
                </Column>
                <Column></Column>
            </Table>
            {!loadingUserLoginBusinessMetrics &&
                paginatedUserLoginsBusinessMetrics &&
                paginatedUserLoginsBusinessMetrics.usersLogin.length > 0 && (
                    <Pagination
                        data-testid="pagination"
                        total={Math.ceil(
                            paginatedUserLoginsBusinessMetrics.totalUserCount /
                                pageSize
                        )}
                        current={pageNum}
                        onPageChange={(page: number) => setPageNum(page)}
                        style={{ float: 'right' }}
                    />
                )}
        </>
    );
};

export default BusinessMetrics;
