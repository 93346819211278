import React from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Input } from '@appkit4/react-components/field';
import { Button } from '@appkit4/react-components/button';
import { Tabs, Tab } from '@appkit4/react-components/tabs';
import {
    UserResponse,
    RoleResponse,
} from '../../../../../api/generated/models';
import useLocale from '../../../../../locale/useLocale';
import Roles from '../../../../../auth/roles';
interface EditUserFormProps {
    roles: RoleResponse[];
    user: UserResponse;
    submitFunc: (values: EditUserFormValues) => void;
}

export interface EditUserFormValues {
    roleId: number;
}

const EditUserForm = (props: EditUserFormProps) => {
    const { l } = useLocale();

    const EditUserSchema = Yup.object({
        roleId: Yup.number()
            .min(Roles.UserId, l('_pleaseSelectAValidRole'))
            .max(Roles.SuperAdminId, l('_pleaseSelectAValidRole'))
            .required(l('_pleaseSelectARole')),
    });

    return (
        <Formik
            initialValues={{
                roleId:
                    props.roles.find((r) => r.name === props.user.roleName)
                        ?.id || -1,
            }}
            validationSchema={EditUserSchema}
            onSubmit={(values) => props.submitFunc(values)}
        >
            {({
                values,
                errors,
                touched,
                setFieldTouched,
                setFieldValue,
                validateField,
            }) => (
                <Form>
                    <div className="row">
                        <div className="col-auto">
                            <div className="row-auto">
                                <Tabs
                                    type="filled"
                                    onTabChange={(i: number, value: string) => {
                                        setFieldTouched('roleId');
                                        validateField('roleId');
                                        setFieldValue('roleId', +value);
                                    }}
                                    aria-label={l('_editUserRole')}
                                    activeIndex={props.roles.findIndex(
                                        (r) => r.id == values.roleId
                                    )}
                                >
                                    {props.roles.map((r) => (
                                        <Tab
                                            className={`roleId${r.id}Tab`}
                                            label={r.name}
                                            value={r.id.toString()}
                                            key={r.id}
                                            // super admin users must have @admin.pwc.com email
                                            disabled={
                                                r.id == Roles.SuperAdminId &&
                                                !props.user?.email.endsWith(
                                                    '@admin.pwc.com'
                                                )
                                            }
                                        />
                                    ))}
                                </Tabs>
                                {touched.roleId && errors.roleId && (
                                    <div className="error-text">
                                        {errors.roleId}
                                    </div>
                                )}
                            </div>
                            {props.roles.some(
                                (r) => r.id == Roles.SuperAdminId
                            ) && (
                                <div className="row">
                                    <p className="ap-typography-body-xs ap-text-neutral-10">
                                        {l('_superAdminEmailRequirement')}
                                    </p>
                                </div>
                            )}
                        </div>
                        {/* Display remaining user info as readonly */}
                    </div>
                    <div className="row">
                        <div className="col-md">
                            <Input
                                type="text"
                                title={l('_forename(s)')}
                                required
                                value={props.user.forenames}
                                disabled
                                className="mt-3"
                            />
                        </div>
                        <div className="col-md">
                            <Input
                                type="text"
                                title={l('_surname')}
                                required
                                value={props.user.surname}
                                disabled
                                className="mt-3"
                            />
                        </div>
                    </div>
                    <Input
                        type="text"
                        title={l('_email')}
                        required
                        value={props.user.email}
                        disabled
                        className="mt-3"
                    />
                    <Button className="ms-auto mt-5" type="submit">
                        {l('_updateUser')}
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

export default EditUserForm;
