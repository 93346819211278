import React from 'react';
import { Header } from '@appkit4/react-components/header';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import AvatarDropdown from './AvatarDropdown';
import { AppRoutes } from '../../Constants';
import useLocale from '../../locale/useLocale';
import { useGetV1Version } from '../../api/generated/endpoint';
import './Navigation.scss';

const Navigation = () => {
    const navigate = useNavigate();
    const auth = useAuth();
    const { l } = useLocale();

    const { data: versionInfo, isLoading: isLoadingVersionInfo } =
        useGetV1Version({
            query: {
                staleTime: Infinity,
            },
        });

    const titleTemplate = () => (
        <Link
            to={
                auth.isAuthenticated
                    ? AppRoutes.HomePage
                    : AppRoutes.LandingPage
            }
            style={{ fontWeight: 'normal', textDecorationLine: 'none' }}
        >
            {l('_applicationName')}
        </Link>
    );

    const optionsTemplate = () => {
        let versionLabel = '';
        if (versionInfo?.versionTag) {
            versionLabel = `${l('_version')} : ${versionInfo.versionTag}`;
        } else if (versionInfo?.branchName && versionInfo?.commitId) {
            versionLabel = `${l('_version')} : ${versionInfo?.branchName} - ${
                versionInfo?.commitId
            }`;
        }

        return (
            <>
                {!isLoadingVersionInfo && versionInfo && (
                    <p
                        id="version-text"
                        className="ap-typography-body-xs pt-3 me-3"
                    >
                        {versionLabel}
                    </p>
                )}
            </>
        );
    };

    const userTemplate = () =>
        auth.isAuthenticated ? <AvatarDropdown /> : null;

    const handleLogoNavigation = () =>
        auth.isAuthenticated
            ? navigate(AppRoutes.HomePage)
            : navigate(AppRoutes.LandingPage);

    const headerStyle = {
        top: '0',
    };

    return (
        <Header
            role="navigation"
            hasLogo
            type="solid"
            titleTemplate={titleTemplate}
            optionsTemplate={optionsTemplate}
            userTemplate={userTemplate}
            onClickLogo={handleLogoNavigation}
            className="position-sticky"
            style={headerStyle}
        />
    );
};

export default Navigation;
