import React from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Input } from '@appkit4/react-components/field';
import { Button } from '@appkit4/react-components/button';
import useLocale from '../../../../../locale/useLocale';

interface RenameTenantFormProps {
    closeFunc: () => void;
    submitFunc: (values: FormValues) => void;
    initialName: string;
}

export interface FormValues {
    name: string;
}

const RenameTenantForm = (props: RenameTenantFormProps) => {
    const { l } = useLocale();

    const RenameTenantSchema = Yup.object({
        name: Yup.string()
            .trim()
            .max(100, l('_pleaseEnterFewerCharacters'))
            .required(l('_pleaseEnterATenantName')),
    });

    return (
        <Formik
            initialValues={{
                name: props.initialName || '',
            }}
            validationSchema={RenameTenantSchema}
            onSubmit={(values) => {
                if (values.name !== props.initialName) {
                    props.submitFunc(values);
                }
                props.closeFunc();
            }}
            onReset={() => props.closeFunc()}
        >
            {({
                values,
                errors,
                touched,
                setFieldTouched,
                setFieldValue,
                validateField,
            }) => (
                <Form>
                    <div className="row mt-3 focus-margin">
                        <div className="col">
                            <Input
                                type="text"
                                title={l('_tenantName')}
                                placeholder={l('_tenantName')}
                                required
                                value={values.name}
                                onChange={(value: string) => {
                                    setFieldTouched('name');
                                    validateField('name');
                                    setFieldValue('name', value);
                                }}
                            />
                            {touched.name && errors.name && (
                                <div className="errorText">{errors.name}</div>
                            )}
                        </div>

                        <div className="col-auto d-flex align-items-center">
                            <Button
                                className="ms-auto h-100 d-flex align-items-center btn-max-height"
                                type="submit"
                            >
                                {l('_renameTenant')}
                            </Button>
                        </div>
                        <div className="col-auto d-flex align-items-center">
                            <Button
                                kind="secondary"
                                className="ms-auto d-flex align-items-center btn-max-height"
                                type="reset"
                            >
                                {l('_cancel')}
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default RenameTenantForm;
