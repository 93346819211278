import React, { FC } from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import GlobalFooter from './GlobalFooter';
import './Layout.scss';
import Navigation from './Navigation';

type LayoutProps = { children?: JSX.Element };

const Layout: FC<LayoutProps> = () => (
    <>
        <Navigation />
        <main
            role="main"
            className="flex-grow-1 ap-container page-container mt-4"
        >
            <Outlet />
        </main>
        <GlobalFooter />
        <ScrollRestoration />
    </>
);

export default Layout;
