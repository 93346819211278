import React, { useState } from 'react';
import { Select } from '@appkit4/react-components/select';
import { Table, Column } from '@appkit4/react-components/table';
import { Loading } from '@appkit4/react-components/loading';
import { Search } from '@appkit4/react-components/search';
import { Button } from '@appkit4/react-components/button';
import { Tooltip } from '@appkit4/react-components/tooltip';
import { Pagination } from '@appkit4/react-components/pagination';
import { useNavigate } from 'react-router-dom';
import useLocale from '../../../../../locale/useLocale';
import { useGetV1TenantSuperAdmin } from '../../../../../api/generated/endpoint';
import useGenericErrorToast from '../../../../../helpers/useGenericErrorToast';
import usePaginationControl from '../../../../../helpers/usePaginationControl';
import { TenantResponse } from '../../../../../api/generated/models';
import CreateTenantModal from './CreateTenantModal';
import TenantAdminsCell from './TenantAdminsCell';
import { AppRoutes } from '../../../../../Constants';
import TenantContactsCell from './TenantContactsCell';
import ConfirmRestoreTenantModal from '../../tenant/superAdmin/ConfirmRestoreTenantModal';
import TenantActionsCell from './TenantActionsCell';

export const enum TenantStatuses {
    Active = 1,
    Deleted = 2,
}

const TenantsTable = () => {
    const { l } = useLocale();
    const navigate = useNavigate();

    const tenantStatuses = [
        { label: l('_active'), value: TenantStatuses.Active },
        { label: l('_deleted'), value: TenantStatuses.Deleted },
    ];

    const [displayCreateTenantModal, setDisplayCreateTenantModal] =
        useState(false);
    const [showRestoreConfirmationForId, setShowRestoreConfirmationForId] =
        useState<number | null>(null);
    const [searchString, setSearchString] = useState<string>('');
    const [tenantStatus, setTenantStatus] = useState<number>(
        TenantStatuses.Active
    );
    const pageSize = 20;
    const [pageNum, setPageNum] = useState<number>(1);
    const skip = (pageNum - 1) * pageSize;
    const take = pageSize;
    const showGenericErrorToast = useGenericErrorToast();

    const { data: paginatedTenants, isLoading: loadingTenants } =
        useGetV1TenantSuperAdmin(
            { skip, take, searchString, tenantStatus },
            {
                query: {
                    onError: showGenericErrorToast,
                },
            }
        );

    /* prevent current page number being invalid */
    usePaginationControl(
        { totalCount: paginatedTenants?.totalTenantCount },
        pageSize,
        setPageNum,
        pageNum
    );

    const renderNameTooltip = (row: TenantResponse) => {
        return (
            <Tooltip
                trigger="hover"
                position="top"
                distance={5}
                id={`tooltip-name`}
                content={row.name}
                style={{
                    wordBreak: 'break-word',
                    overflowWrap: 'break-word',
                    whiteSpace: 'normal',
                }}
            >
                <span>{row.name}</span>
            </Tooltip>
        );
    };

    let typingTimer: NodeJS.Timeout; //timer identifier
    const doneTypingIntervals = 1000; //time in ms

    return (
        <>
            <div className="row mt-4">
                {((!loadingTenants && paginatedTenants) ||
                    searchString != '') && (
                    <>
                        <div className="col-8 d-flex">
                            <Search
                                searchType="primary"
                                placeholder={l('_search')}
                                disabled={loadingTenants}
                                onSearch={(searchString) =>
                                    setSearchString(searchString)
                                }
                                onChange={(search: string, event) => {
                                    clearTimeout(typingTimer);
                                    if (!search) {
                                        setSearchString('');
                                    } else {
                                        typingTimer = setTimeout(() => {
                                            // simulate pressing enter on the search box
                                            // so the onSearch method handles all the actual searching
                                            event.target.dispatchEvent(
                                                new KeyboardEvent('keydown', {
                                                    code: 'Enter',
                                                    key: 'Enter',
                                                    charCode: 13,
                                                    keyCode: 13,
                                                    view: window,
                                                    bubbles: true,
                                                })
                                            );
                                        }, doneTypingIntervals);
                                    }
                                }}
                                onClear={() => setSearchString('')}
                                className="m-0 p-0 pe-2"
                            />
                            <div>
                                <Select
                                    data={tenantStatuses}
                                    searchable={false}
                                    defaultValue={tenantStatus}
                                    placeholder={'User status'}
                                    onSelect={(selected) =>
                                        setTenantStatus(Number(selected))
                                    }
                                    data-testid="tenant-status-select"
                                />
                            </div>
                        </div>
                        <div className="col-4 d-flex align-items-center">
                            <Button
                                kind="primary"
                                icon="icon-plus-outline"
                                type="button"
                                role="button"
                                onClick={() =>
                                    setDisplayCreateTenantModal(true)
                                }
                                className="ms-auto align-top d-flex align-items-center btn-max-height"
                            >
                                {l('_addTenant')}
                            </Button>
                        </div>
                    </>
                )}
            </div>

            {loadingTenants ? (
                <Loading loadingType="circular" indeterminate compact={false} />
            ) : paginatedTenants && paginatedTenants.tenants.length > 0 ? (
                <>
                    <Table
                        className="ap-mt-spacing-4 admin-table-container"
                        originalData={paginatedTenants?.tenants}
                        condensed
                        striped
                        hasTitle
                        onRowClick={(e, i, data) => {
                            if (tenantStatus === TenantStatuses.Active) {
                                navigate(`${AppRoutes.Tenant}/${data.id}`);
                            }
                        }}
                    >
                        <Column field="name" renderCell={renderNameTooltip}>
                            {l('_name')}
                        </Column>
                        <Column
                            renderCell={(row: TenantResponse) => (
                                <TenantAdminsCell tenant={row} />
                            )}
                            field="admins"
                        >
                            {l('_admins')}
                        </Column>
                        <Column
                            renderCell={(row: TenantResponse) => (
                                <div className="d-flex">
                                    <TenantContactsCell tenant={row} />
                                    {tenantStatus === TenantStatuses.Active && (
                                        <span
                                            className="Appkit4-icon icon-arrow-right-fill tenant-table-icon"
                                            data-testid="select-tenant-icon-button"
                                        />
                                    )}
                                </div>
                            )}
                            field="contactPersons"
                        >
                            {l('_contacts')}
                        </Column>
                        {tenantStatus === TenantStatuses.Deleted && (
                            <Column
                                className="col-1"
                                renderCell={(row: TenantResponse) => (
                                    <TenantActionsCell
                                        tenant={row}
                                        displayRestoreTenantModal={() => {
                                            setShowRestoreConfirmationForId(
                                                row.id
                                            );
                                        }}
                                    />
                                )}
                                field="id"
                            >
                                {l('_actions')}
                            </Column>
                        )}
                    </Table>

                    <Pagination
                        data-testid="pagination"
                        total={Math.ceil(
                            paginatedTenants.totalTenantCount / pageSize
                        )}
                        current={pageNum}
                        onPageChange={(page: number) => setPageNum(page)}
                        style={{ float: 'right' }}
                    />
                </>
            ) : (
                <Table
                    className="ap-mt-spacing-4 mt-2 text-center admin-table-container"
                    originalData={{
                        message: (
                            <p className="m-0 error-text-heading">
                                {searchString === ''
                                    ? l('_noTenantsFound')
                                    : l('_noSearchResultsFound')}
                            </p>
                        ),
                    }}
                >
                    <Column field="message" />
                </Table>
            )}

            {displayCreateTenantModal && (
                <CreateTenantModal
                    isVisible
                    setIsVisible={setDisplayCreateTenantModal}
                />
            )}

            {showRestoreConfirmationForId && (
                <ConfirmRestoreTenantModal
                    visible={true}
                    tenantInfo={paginatedTenants?.tenants.find(
                        (t) => t.id === showRestoreConfirmationForId
                    )}
                    setNotVisible={() => setShowRestoreConfirmationForId(null)}
                />
            )}
        </>
    );
};

export default TenantsTable;
