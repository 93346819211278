import React from 'react';
import { Modal } from '@appkit4/react-components/modal';
import { Button } from '@appkit4/react-components/button';
import useGenericErrorToast from '../../../../../helpers/useGenericErrorToast';
import useToast from '../../../../../helpers/useToast';
import useLocale from '../../../../../locale/useLocale';
import ModalContainer from '../../../../ModalContainer';
import {
    DeleteV1TenantSuperAdminTenantIdMutationError,
    useDeleteV1TenantSuperAdminTenantId,
} from '../../../../../api/generated/endpoint';
import { SuperAdminTenantResponse } from '../../../../../api/generated/models';
import { AppRoutes } from '../../../../../Constants';
import { useNavigate } from 'react-router-dom';

interface ConfirmDeleteTenantModalProps {
    visible: boolean;
    tenant: SuperAdminTenantResponse | undefined;
    setNotVisible: () => void;
}

const ConfirmDeleteTenantModal = (props: ConfirmDeleteTenantModalProps) => {
    const showToast = useToast();
    const { l } = useLocale();
    const showGenericErrorToast = useGenericErrorToast();
    const deleteTenant = useDeleteV1TenantSuperAdminTenantId();
    const navigate = useNavigate();

    const handleConfirmDelete = () => {
        deleteTenant.mutate(
            { tenantId: props.tenant?.id ?? -1 },
            {
                onSuccess: () => {
                    showToast(
                        l('_success'),
                        l('_tenantHasBeenDeleted'),
                        'success'
                    );
                    props.setNotVisible();
                    navigate(AppRoutes.Management);
                },
                onError: (
                    error: DeleteV1TenantSuperAdminTenantIdMutationError
                ) => {
                    showGenericErrorToast(error);
                    props.setNotVisible();
                },
            }
        );
    };

    return (
        <>
            {props.visible && (
                <ModalContainer isVisible={props.visible}>
                    <Modal
                        visible
                        maskCloseable
                        closable
                        closeAriaLabel={l('_close')}
                        title={l('_confirmTenantDeletion')}
                        aria-label={`${l('_confirmTenantDeletetionWithName')} ${
                            props.tenant?.name ?? '-'
                        }`}
                        onCancel={props.setNotVisible}
                        modalStyle={{
                            width: '50vw',
                            maxWidth: '500px',
                            minWidth: '250px',
                        }}
                        footerStyle={{ marginTop: '1rem' }}
                        footer={
                            <>
                                <Button
                                    kind="secondary"
                                    onClick={props.setNotVisible}
                                    className="focus-margin"
                                >
                                    {l('_cancel')}
                                </Button>
                                <Button
                                    kind="negative"
                                    onClick={() => handleConfirmDelete()}
                                    className="focus-margin"
                                >
                                    {l('_delete')}
                                </Button>
                            </>
                        }
                    >
                        <p>{l('_areYouSureYouWantToDeleteTenant')}</p>
                        <div className="row mt-2">
                            <div className="col">
                                {props.tenant?.name ?? '-'}
                            </div>
                        </div>
                    </Modal>
                </ModalContainer>
            )}
        </>
    );
};

export default ConfirmDeleteTenantModal;
