import React, { useEffect } from 'react';

import {
    LandingPage,
    Home,
    Settings,
    NotFound,
    Logout,
    NotAuthorised,
    Cookies,
    TandC,
    Privacy,
    Contact,
    Accessibility,
    LoginCallback,
} from './pages';

import AdminTabs from './components/layout/AdminTabs';

import ProtectedRedirect from './components/auth/ProtectedRedirect';
import AdminRedirect from './components/auth/admin/AdminRedirect';
import { AppRoutes } from './Constants';
import Layout from './components/layout/Layout';
import UserInfoWrapper from './helpers/UserInfoWrapper';
import { useApplyDarkMode } from './helpers/DarkModeDetector';
import { ApplicationPolicies, Management, Tenant } from './pages/admin';
import SuperAdminRedirect from './components/auth/superAdmin/SuperAdminRedirect';
import { BusinessMetrics } from './pages/metrics';
import i18nSetup from './locale/i18nSetup';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const App = () => {
    i18nSetup();

    useApplyDarkMode();

    const colourTheme: 'orange' | 'pink' | 'red' | 'black' = 'orange';

    useEffect(() => {
        document.body.setAttribute('data-theme', colourTheme);
        return () => document.body.removeAttribute('data-theme');
    }, [colourTheme]);

    const router = createBrowserRouter([
        {
            path: AppRoutes.LandingPage,
            element: <LandingPage />,
            index: true,
        },
        {
            // Routes accessible to authenticated users
            element: <Layout />,
            children: [
                {
                    element: (
                        <ProtectedRedirect>
                            <UserInfoWrapper />
                        </ProtectedRedirect>
                    ),
                    children: [
                        {
                            path: AppRoutes.HomePage,
                            element: <Home />,
                        },
                        {
                            path: AppRoutes.SettingsPage,
                            element: <Settings />,
                        },
                        {
                            // Routes accessible to admins and super admins
                            element: <AdminRedirect />,
                            children: [
                                {
                                    element: <AdminTabs />,
                                    children: [
                                        {
                                            path: AppRoutes.Management,
                                            element: <Management />,
                                        },

                                        {
                                            element: <SuperAdminRedirect />,
                                            children: [
                                                {
                                                    path: AppRoutes.ApplicationPolicies,
                                                    element: (
                                                        <ApplicationPolicies />
                                                    ),
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            element: <SuperAdminRedirect />,
                            children: [
                                {
                                    path: AppRoutes.BusinessMetrics,
                                    element: <BusinessMetrics />,
                                },
                                {
                                    path: `${AppRoutes.Tenant}/:tenantId`,
                                    element: <Tenant />,
                                },
                            ],
                        },
                    ],
                },
                {
                    path: AppRoutes.CookiesPage,
                    element: <Cookies />,
                },
                {
                    path: AppRoutes.TandC,
                    element: <TandC />,
                },
                {
                    path: AppRoutes.PrivacyPage,
                    element: <Privacy />,
                },
                {
                    path: AppRoutes.ContactPage,
                    element: <Contact />,
                },
                {
                    path: AppRoutes.AccessibilityStatement,
                    element: <Accessibility />,
                },
                {
                    path: AppRoutes.LogoutPage,
                    element: <Logout />,
                },
                {
                    path: '/login/callback',
                    element: <LoginCallback />,
                },
                {
                    path: '/403',
                    element: <NotAuthorised />,
                },
                {
                    path: '*',
                    element: <NotFound />,
                },
            ],
        },
    ]);

    return (
        <div className="d-flex flex-column h-100 min-vh-100">
            <RouterProvider router={router} />
        </div>
    );
};

export default App;
