import React, { useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { List, ListItem } from '@appkit4/react-components/list';
import { Loading } from '@appkit4/react-components/loading';
import useGenericErrorToast from '../../helpers/useGenericErrorToast';
import { ContactPersonResponse } from '../../api/generated/models';
import Roles from '../../auth/roles';
import { Button } from '@appkit4/react-components';
import CreateApplicationContactModal from '../../components/pages/policy/contact/superAdmin/CreateApplicationContactModal';
import EditApplicationContactModal from '../../components/pages/policy/contact/superAdmin/EditApplicationContactModal';
import useLocale from '../../locale/useLocale';
import {
    useGetV1ContactPersonApplication,
    useGetV1ContactPersonTenantTenantId,
    useGetV1IdentityUserInfo,
} from '../../api/generated/endpoint';
import useTitle from '../../helpers/useTitle';
import ConfirmDeleteContactPersonModal from '../../components/ConfirmDeleteContactPersonModal';

type ContactItem = {
    id: number;
    key: string;
    value: string;
    action?: string;
};

const Contact = () => {
    const auth = useAuth();
    const showGenericErrorToast = useGenericErrorToast();
    const { l } = useLocale();
    useTitle(l('_contactUsTitleCase'));

    const [
        isCreateApplicationContactModalVisible,
        setIsCreateApplicationContactModalVisible,
    ] = useState(false);

    const [
        isEditApplicationContactModalVisible,
        setIsEditApplicationContactModalVisible,
    ] = useState(false);

    const [applicationContactToEdit, setApplicationContactToEdit] = useState<
        ContactPersonResponse | undefined
    >(undefined);

    const [
        isDeleteApplicationContactModalVisible,
        setIsDeleteApplicationContactModalVisible,
    ] = useState(false);

    const [applicationContactToDelete, setApplicationContactToDelete] =
        useState<ContactPersonResponse | undefined>(undefined);

    const { data: userInfo } = useGetV1IdentityUserInfo({
        query: {
            enabled: auth.isAuthenticated,
            staleTime: Infinity,
        },
    });

    const {
        data: applicationContacts,
        isLoading: isLoadingApplicationContacts,
    } = useGetV1ContactPersonApplication({
        query: {
            onError: showGenericErrorToast,
        },
    });

    const { data: tenantContacts, isLoading: isLoadingTenantContacts } =
        // The query is only enabled when the users tenantId is not null
        useGetV1ContactPersonTenantTenantId(userInfo?.tenantId ?? -1, {
            query: {
                enabled: auth.isAuthenticated && userInfo?.tenantId != null,
                onError: showGenericErrorToast,
            },
        });

    const renderContactPerson = (
        item: ContactItem,
        index: number,
        isApplicationContact: boolean
    ) => {
        return (
            <div className="row">
                <div className="col">
                    <ListItem
                        key={index}
                        role="option"
                        aria-label={`${item.key},${item.value}`}
                    >
                        <span className="primary-text">{item.key}</span>
                        <span className="secondary-text">{item.value}</span>
                    </ListItem>
                </div>
                {isApplicationContact && (
                    <>
                        {/* Super admin can edit and delete application contacts */}
                        {userInfo?.roleId === Roles.SuperAdminId && (
                            <>
                                <div className="col-auto d-flex align-items-center">
                                    <Button
                                        className="m-0 p-0 h-50"
                                        kind="text"
                                        icon="icon-edit-outline"
                                        type="button"
                                        role="button"
                                        compact
                                        onClick={() => {
                                            setApplicationContactToEdit(
                                                applicationContacts?.find(
                                                    (ac) => ac.id == item.id
                                                )
                                            );
                                            setIsEditApplicationContactModalVisible(
                                                true
                                            );
                                        }}
                                        title={`${l('_editContactPerson')} ${
                                            item.key
                                        }`}
                                    />
                                </div>

                                <div className="col-auto d-flex align-items-center">
                                    <Button
                                        className="m-0 p-0 h-50"
                                        kind="text"
                                        icon="icon-delete-outline"
                                        type="button"
                                        role="button"
                                        compact
                                        onClick={() => {
                                            setApplicationContactToDelete(
                                                applicationContacts?.find(
                                                    (ac) => ac.id == item.id
                                                )
                                            );
                                            setIsDeleteApplicationContactModalVisible(
                                                true
                                            );
                                        }}
                                        title={`${l('_deleteContactPerson')} ${
                                            item.key
                                        }`}
                                    />
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        );
    };

    return (
        <>
            <h1 data-cy="contact-us-title">{l('_contactUsTitleCase')}</h1>
            <div className="mt-4">
                <p>{l('_contactUsWithFeedback')}</p>

                <div className="mb-4">
                    <div className="d-flex flex-row align-items-end">
                        <h2>{l('_applicationContact')}</h2>
                        {/* If no application contact exists, super admins can create one */}
                        {userInfo?.roleId === Roles.SuperAdminId &&
                            !isLoadingApplicationContacts &&
                            applicationContacts && (
                                <Button
                                    kind="primary"
                                    icon="icon-plus-outline"
                                    type="button"
                                    role="button"
                                    onClick={() =>
                                        setIsCreateApplicationContactModalVisible(
                                            true
                                        )
                                    }
                                    className="mb-3 ms-auto"
                                >
                                    {l('_addApplicationContact')}
                                </Button>
                            )}
                    </div>
                    {isLoadingApplicationContacts ? (
                        <Loading
                            loadingType="circular"
                            indeterminate
                            compact={false}
                        />
                    ) : applicationContacts &&
                      applicationContacts.length > 0 ? (
                        <List
                            aria-label={l('_applicationContacts')}
                            itemKey="id"
                            bordered
                            data={applicationContacts.map((contact) => ({
                                id: contact.id,
                                key: `${contact.forenames} ${contact.surname}`,
                                value: contact.email,
                            }))}
                            renderItem={(item, index) =>
                                renderContactPerson(item, index, true)
                            }
                            className="d-inline-block"
                            style={{ width: '100%' }}
                        />
                    ) : (
                        <p>{l('_noContactsConfiguredForApplication')}</p>
                    )}
                </div>

                {/* Only display tenant contacts for logged-in users */}
                {auth.isAuthenticated && (
                    <div className="mb-2">
                        <div className="d-flex flex-row align-items-end">
                            <h2>{l('_tenantContacts')}</h2>
                        </div>

                        {isLoadingTenantContacts ? (
                            <Loading
                                loadingType="circular"
                                indeterminate
                                compact={false}
                            />
                        ) : tenantContacts && tenantContacts.length > 0 ? (
                            <List
                                aria-label={l('_tenantContacts')}
                                itemKey="id"
                                bordered
                                data={tenantContacts.map((contact) => ({
                                    id: contact.id,
                                    key: `${contact.forenames} ${contact.surname}`,
                                    value: contact.email,
                                }))}
                                renderItem={(item, index) =>
                                    renderContactPerson(item, index, false)
                                }
                                className="d-inline-block"
                                style={{ width: '100%' }}
                            />
                        ) : (
                            <p>{l('_noContactsConfiguredForTenant')}</p>
                        )}
                    </div>
                )}
            </div>

            {userInfo?.roleId === Roles.SuperAdminId && (
                <>
                    <CreateApplicationContactModal
                        isVisible={isCreateApplicationContactModalVisible}
                        setNotVisible={() =>
                            setIsCreateApplicationContactModalVisible(false)
                        }
                    />

                    {applicationContactToEdit && (
                        <EditApplicationContactModal
                            applicationContactToEdit={applicationContactToEdit}
                            isVisible={isEditApplicationContactModalVisible}
                            setNotVisible={() => {
                                setIsEditApplicationContactModalVisible(false);
                                setApplicationContactToEdit(undefined);
                            }}
                        />
                    )}

                    {applicationContactToDelete && (
                        <ConfirmDeleteContactPersonModal
                            contactPerson={applicationContactToDelete}
                            visible={isDeleteApplicationContactModalVisible}
                            setNotVisible={() => {
                                setIsDeleteApplicationContactModalVisible(
                                    false
                                );
                                setApplicationContactToDelete(undefined);
                            }}
                            tenantId={undefined}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default Contact;
