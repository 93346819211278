import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import RemarkGFM from 'remark-gfm';
import { TextArea } from '@appkit4/react-components/field';
import { Loading } from '@appkit4/react-components/loading';
import { Tabs, Tab } from '@appkit4/react-components/tabs';
import { Checkbox } from '@appkit4/react-components/checkbox';
import useGenericErrorToast from '../../../../../helpers/useGenericErrorToast';
import { Button, Panel } from '@appkit4/react-components';
import useToast from '../../../../../helpers/useToast';
import { Policies } from '../../../../../api/Policies';
import {
    PutV1PolicySuperAdminPolicyIdMutationError,
    getGetV1PolicyCookiesQueryKey,
    getGetV1PolicyPrivacyQueryKey,
    getGetV1PolicyTermsQueryKey,
    useGetV1PolicyCookies,
    useGetV1PolicyPrivacy,
    useGetV1PolicyTerms,
    usePutV1PolicySuperAdminPolicyId,
} from '../../../../../api/generated/endpoint';
import useLocale from '../../../../../locale/useLocale';
import { useQueryClient } from '@tanstack/react-query';

interface EditPolicyTextProps {
    policy: Policies;
    className?: string;
}

type PolicyQuery =
    | typeof useGetV1PolicyPrivacy
    | typeof useGetV1PolicyCookies
    | typeof useGetV1PolicyTerms
    | undefined;

const EditPolicyText = (props: EditPolicyTextProps) => {
    const showToast = useToast();
    const { l } = useLocale();
    const showGenericErrorToast = useGenericErrorToast();
    const updatePolicyText = usePutV1PolicySuperAdminPolicyId();
    const queryClient = useQueryClient();

    const [isPreviewPolicy, setIsPreviewPolicy] = useState(false);
    const [editedPolicy, setEditedPolicy] = useState<string | null>(null);
    const [isMajorVersionUpdate, setIsMajorVersionUpdate] = useState(false);

    let policyTitle: string;
    let policyTestId: string;
    let policyUpdateText: string;
    let policyQuery: PolicyQuery;

    /* select which queryKey/queryFn to use based upon policy */
    switch (props.policy) {
        case Policies.PrivacyPolicy: {
            policyTitle = l('_privacyPolicy');
            policyTestId = 'privacyTestId';
            policyUpdateText = l('_updatePrivacyPolicy');
            policyQuery = useGetV1PolicyPrivacy;
            break;
        }
        case Policies.TermsAndConditions: {
            policyTitle = l('_termsAndConditions');
            policyTestId = 'termsTestId';
            policyUpdateText = l('_updateTermsAndConditions');
            policyQuery = useGetV1PolicyTerms;
            break;
        }
        case Policies.CookiePolicy: {
            policyTitle = l('_cookiePolicy');
            policyTestId = 'cookieTestId';
            policyUpdateText = l('_updateCookiePolicy');
            policyQuery = useGetV1PolicyCookies;
            break;
        }
    }

    const { data: policy, isLoading: isLoadingPolicy } = policyQuery({
        query: {
            onError: showGenericErrorToast,
        },
    });

    const handleUpdatePolicyText = () => {
        if (!policy || !editedPolicy) {
            showToast(l('_error'), l('_cannotUpdatePolicyRightNow'), 'error');
            return;
        }

        updatePolicyText.mutate(
            {
                policyId: policy.id,
                data: {
                    text: editedPolicy,
                    isMajorVersionUpdate: isMajorVersionUpdate,
                },
            },
            {
                onSuccess: () => {
                    showToast(
                        l('_success'),
                        l('_policyTextUpdatedSuccessfully'),
                        'success'
                    );
                    setEditedPolicy(null);
                    switch (policy.id) {
                        case Policies.PrivacyPolicy:
                            queryClient.invalidateQueries(
                                getGetV1PolicyPrivacyQueryKey()
                            );
                            break;
                        case Policies.TermsAndConditions:
                            queryClient.invalidateQueries(
                                getGetV1PolicyTermsQueryKey()
                            );
                            break;
                        case Policies.CookiePolicy:
                            queryClient.invalidateQueries(
                                getGetV1PolicyCookiesQueryKey()
                            );
                            break;
                        default:
                            break;
                    }
                },
                onError: (
                    error: PutV1PolicySuperAdminPolicyIdMutationError
                ) => {
                    showGenericErrorToast(error);
                },
            }
        );
    };

    return (
        <>
            <Panel className={`pt-2 ${props.className}`}>
                <div className="row">
                    <div className="col">
                        <h2>{policyTitle}</h2>
                    </div>
                    <div className="col-auto ms-auto">
                        <Tabs
                            type="filled"
                            className="mt-3"
                            responsive={false}
                            onTabChange={(i: number) =>
                                setIsPreviewPolicy(i === 1)
                            }
                        >
                            <Tab
                                label={l('_edit')}
                                value="Edit"
                                icon="icon-edit-outline"
                            />
                            <Tab
                                label={l('_preview')}
                                value="Preview"
                                icon="icon-view-outline"
                            />
                        </Tabs>
                    </div>
                </div>
                {isLoadingPolicy ? (
                    <Loading
                        loadingType="circular"
                        indeterminate
                        className="mt-2"
                    />
                ) : (
                    <>
                        {isPreviewPolicy ? (
                            <ReactMarkdown
                                remarkPlugins={[RemarkGFM]}
                                className="table-bordered"
                            >
                                {editedPolicy != null
                                    ? editedPolicy
                                    : policy?.text || ''}
                            </ReactMarkdown>
                        ) : (
                            <TextArea
                                title={policy?.title || ''}
                                data-testid={policyTestId}
                                value={
                                    editedPolicy != null
                                        ? editedPolicy
                                        : policy?.text || ''
                                }
                                onChange={(value: string) => {
                                    if (value === policy?.text) {
                                        setEditedPolicy(null);
                                    } else {
                                        setEditedPolicy(value);
                                    }
                                }}
                            />
                        )}
                        <Checkbox
                            checked={isMajorVersionUpdate}
                            onChange={(checked: boolean) =>
                                setIsMajorVersionUpdate(checked)
                            }
                            disabled={
                                editedPolicy === null || policy === undefined
                            }
                        >
                            {l('_tickBoxIfThisIsMajorVersionUpdate')}
                        </Checkbox>
                        <Button
                            disabled={
                                editedPolicy === null || policy === undefined
                            }
                            className="ms-auto mt-4"
                            onClick={handleUpdatePolicyText}
                        >
                            {policyUpdateText}
                        </Button>
                    </>
                )}
            </Panel>
        </>
    );
};

export default EditPolicyText;
