import React from 'react';
import { Modal } from '@appkit4/react-components/modal';
import useGenericErrorToast from '../../../../../helpers/useGenericErrorToast';
import EditUserForm, { EditUserFormValues } from './EditUserForm';
import useToast from '../../../../../helpers/useToast';
import { UserResponse } from '../../../../../api/generated/models';
import { Loading } from '@appkit4/react-components';
import ModalContainer from '../../../../ModalContainer';
import {
    useGetV1IdentityUserInfo,
    useGetV1RoleAdminAll,
    usePutV1UserAdminUserId,
    PutV1UserAdminUserIdMutationError,
    getGetV1UserAdminQueryKey,
    getGetV1TenantSuperAdminQueryKey,
} from '../../../../../api/generated/endpoint';
import useLocale from '../../../../../locale/useLocale';
import { useQueryClient } from '@tanstack/react-query';

interface EditUserProps {
    isVisible: boolean;
    setNotVisible: () => void;
    user: UserResponse | undefined;
}

const EditUserModal = (props: EditUserProps) => {
    const showToast = useToast();
    const { l } = useLocale();
    const showGenericErrorToast = useGenericErrorToast();
    const updateUser = usePutV1UserAdminUserId();
    const queryClient = useQueryClient();

    if (props.user === undefined) {
        showToast(l('_error'), l('_unableToRetrieveInfoForUser'), 'error');
        props.setNotVisible();
    }

    const { data: userInfo } = useGetV1IdentityUserInfo({
        query: {
            staleTime: Infinity,
        },
    });

    const { data: roles, isLoading: loadingRoles } = useGetV1RoleAdminAll({
        query: {
            onError: (error) => {
                showGenericErrorToast(error);
                props.setNotVisible();
            },
            staleTime: Infinity,
        },
    });

    const putEditUserRequest = (values: EditUserFormValues) => {
        if (props.user === undefined) {
            return; // do not expect this to ever occur
        }

        updateUser.mutate(
            { userId: props.user.id, data: { roleId: values.roleId } },
            {
                onSuccess: () => {
                    showToast(
                        l('_success'),
                        l('_userUpdatedSuccessfully'),
                        'success'
                    );
                    props.setNotVisible();
                    queryClient.invalidateQueries(getGetV1UserAdminQueryKey());
                    queryClient.invalidateQueries(
                        getGetV1TenantSuperAdminQueryKey()
                    );
                },
                onError: (error: PutV1UserAdminUserIdMutationError) => {
                    showGenericErrorToast(error);
                    props.setNotVisible();
                },
            }
        );
    };

    return (
        <>
            {props.isVisible && (
                <ModalContainer isVisible={props.isVisible}>
                    <Modal
                        visible
                        closable
                        closeAriaLabel={l('_close')}
                        title={l('_editUser')}
                        aria-label={l('_editUser')}
                        modalStyle={{
                            width: '50vw',
                            maxWidth: '500px',
                            minWidth: '250px',
                        }}
                        closeOnPressEscape
                        onCancel={() => props.setNotVisible()}
                    >
                        {loadingRoles || !userInfo ? (
                            <Loading
                                loadingType="circular"
                                indeterminate
                                compact={false}
                            />
                        ) : (
                            <EditUserForm
                                // only pass roles up to and including current user role
                                roles={
                                    roles?.filter(
                                        (r) => r.id <= (userInfo?.roleId ?? -1)
                                    ) ?? []
                                }
                                submitFunc={putEditUserRequest}
                                user={props.user as UserResponse} // able to cast as ensured not undefined at start
                            />
                        )}
                    </Modal>
                </ModalContainer>
            )}
        </>
    );
};

export default EditUserModal;
