import React, { useEffect, useState } from 'react';
import './Home.scss';
import HeroImage from '../assets/illustrations/HeroImage.svg';
import UsersEveryone from '../assets/illustrations/UsersEveryone.svg';
import BrandIllustrationDekstop from '../assets/illustrations/BrandIllustrationDesktop.svg';
import BrandIllustrationMobile from '../assets/illustrations/BrandIllustrationMobile.svg';
import FeedbackIllustration from '../assets/illustrations/FeedbackIllustration.svg';
import { ReactComponent as HeroIcon } from '../assets/icons/HeroIcon.svg';
import useLocale from '../locale/useLocale';
import useTitle from '../helpers/useTitle';

export const Home = () => {
    const { l } = useLocale();
    useTitle(l('_home'));

    const [topPosition, setTopPosition] = useState<string>('150px');

    const updateTopPosition = (): void => {
        const heroTop = document.querySelector('.hero-top') as HTMLElement;
        const height = heroTop ? heroTop.offsetHeight : 0;
        setTopPosition(`${height - 145}px`);
    };

    useEffect(() => {
        updateTopPosition();
        window.addEventListener('resize', updateTopPosition);

        return () => {
            window.removeEventListener('resize', updateTopPosition);
        };
    }, []);

    useEffect(() => {
        const mainElement = document.querySelector('.page-container');
        const footerElement = document.querySelector('.footer-wrapper');

        if (mainElement) {
            mainElement.classList.remove('mt-4');
            mainElement.classList.remove('page-container');
            mainElement.classList.add('home-main');
        }

        if (footerElement) {
            footerElement.classList.remove('mt-5');
        }

        return () => {
            if (mainElement) {
                mainElement.classList.add('mt-4');
                mainElement.classList.add('page-container');
                mainElement.classList.remove('home-main');
            }
            if (footerElement) {
                footerElement.classList.add('mt-5');
            }
        };
    }, []);

    return (
        <>
            <div className="homepage-container">
                <section className="hero">
                    <div className="hero-content">
                        <div className="hero-top ap-bg-color-background-alt full-bleed">
                            <div className="hero-title">
                                <div className="hero-icon">
                                    <HeroIcon
                                        className="hero-icon-svg"
                                        aria-label={l(
                                            '_redGeometricIllustration'
                                        )}
                                    />
                                </div>
                                <h1 className="hero-title-text">
                                    {l('_applicationName')}
                                </h1>
                            </div>
                            <div className="hero-title-subtext">
                                <p>{l('_WebAppAcceleratorSubtitle')}</p>
                            </div>
                        </div>
                        <div className="hero-bottom">
                            <div className="hero-bottom-text">
                                <p>{l('_WebAppAcceleratorSubtext')}</p>
                            </div>
                        </div>
                    </div>
                    <div
                        className="hero-image-container"
                        style={{ top: topPosition }}
                    >
                        <img
                            className="hero-image ms-5"
                            src={HeroImage}
                            alt={l('_personSatAtDeskWithComputer')}
                        />
                    </div>
                </section>
                <section className="users-home ap-bg-color-background-alt full-bleed">
                    <div className="users-home-title">
                        <div className="users-image-container">
                            <img
                                className="users-image"
                                src={UsersEveryone}
                                alt={l(
                                    '_casuallyStandingBusinessPeopleIllustration'
                                )}
                            />
                        </div>
                        <div className="users-text">
                            <h2>{l('_WebAppAcceleratorForEveryone')}</h2>
                        </div>
                    </div>
                    <div className="users-grid">
                        <div className="grid-box">
                            <span
                                className="pwc-brand-icon-xlarge icon-art-themes icons"
                                role="img"
                                aria-label={l('_colourPaletteIcon')}
                            />
                            <p>{l('_frontendDeveloper')}</p>
                        </div>
                        <div className="grid-box">
                            <span
                                className="pwc-brand-icon-xlarge icon-immersivetechnology icons"
                                role="img"
                                aria-label={l(
                                    '_personSurroundedByImmersiveTechnologyIcon'
                                )}
                            />
                            <p>{l('_backendDeveloper')}</p>
                        </div>
                        <div className="grid-box">
                            <span
                                className="pwc-brand-icon-xlarge icon-labs icons"
                                role="img"
                                aria-label={l('_laboratoryFlasksIcon')}
                            />
                            <p>{l('_tester')}</p>
                        </div>
                        <div className="grid-box">
                            <span
                                className="pwc-brand-icon-xlarge icon-calendar icons"
                                role="img"
                                aria-label={l('_calendarIcon')}
                            />
                            <p>{l('_productManager')}</p>
                        </div>
                        <div className="grid-box">
                            <span
                                className="pwc-brand-icon-xlarge icon-bot icons"
                                role="img"
                                aria-label={l('_robotIcon')}
                            />
                            <p>{l('_devOpsEngineer')}</p>
                        </div>
                        <div className="grid-box">
                            <span
                                className="pwc-brand-icon-xlarge icon-cogs icons"
                                role="img"
                                aria-label={l('_cogsIcon')}
                            />
                            <p>{l('_technicalArchitect')}</p>
                        </div>
                    </div>
                </section>
                <section className="brand-home">
                    <div className="brand-text">
                        <div className="brand-text-title">
                            <h2>{l('_stayOnBrand')}</h2>
                        </div>
                        <div className="brand-image-mobile-container">
                            <img
                                className="brand-image-mobile"
                                src={BrandIllustrationMobile}
                                alt={l('_geometricPattern')}
                            />
                        </div>
                        <div className="brand-text-subtext">
                            <p>
                                {l('_weHaveUsedComponentsFrom')}{' '}
                                <a href="https://appkit.pwc.com/appkit4/content/getting-started?version=react">
                                    {l('_Appkit')}
                                </a>{' '}
                                {l('_andContentFrom')}{' '}
                                <a href="https://brand.pwc.com/">
                                    {l('_PwCBrand')}
                                </a>
                                . <br />
                                {l(
                                    '_applicationUpdatesWillBeLoggedAndSharedIn'
                                )}{' '}
                                <a href="https://github.com/pwc-uk-tech-central/skeletON">
                                    {l('_WebAppAcceleratorGithubRepository')}
                                </a>
                                {l(
                                    '_toContinueDevelopingCheckTheseSitesToEnsureBrandAndApproval'
                                )}
                            </p>
                        </div>
                    </div>
                    <div className="brand-image-desktop-container">
                        <img
                            className="brand-image-desktop"
                            src={BrandIllustrationDekstop}
                            alt={l('_geometricPattern')}
                        />
                    </div>
                </section>
                <section className="feedback-home full-bleed-primary">
                    <div className="feedback-image-container">
                        <img
                            className="feedback-image"
                            src={FeedbackIllustration}
                            alt={l('_twoPeopleShakingHandsIllustration')}
                        />
                    </div>
                    <div className="feedback-text">
                        <h2>{l('_helpUsImprove')}</h2>
                        <p>
                            {l('_WebAppAcceleratorIntention')} <br />
                            <br /> {l(
                                '_toProvideThoughtsAndFeedbackUseOur'
                            )}{' '}
                            <a href="https://webappaccelerator.ideas.aha.io/">
                                {l('_AhaIdeasPortal')}
                            </a>
                        </p>
                    </div>
                </section>
            </div>
        </>
    );
};

export default Home;
