import React from 'react';
import { Modal } from '@appkit4/react-components/modal';
import {
    ContactPersonResponse,
    EditContactPersonRequest,
} from '../../../../../api/generated/models';
import useToast from '../../../../../helpers/useToast';
import useLocale from '../../../../../locale/useLocale';
import ModalContainer from '../../../../ModalContainer';
import EditContactPersonForm from '../../../../EditContactPersonForm';
import useGenericErrorToast from '../../../../../helpers/useGenericErrorToast';
import {
    PutV1ContactPersonAdminContactPersonIdMutationError,
    getGetV1ContactPersonTenantTenantIdQueryKey,
    getGetV1TenantSuperAdminQueryKey,
    usePutV1ContactPersonAdminContactPersonId,
} from '../../../../../api/generated/endpoint';
import { useQueryClient } from '@tanstack/react-query';

interface EditContactPersonModalProps {
    isVisible: boolean;
    setNotVisible: () => void;
    contactPerson: ContactPersonResponse | undefined;
    tenantId: number;
}

const EditContactPersonModal = (props: EditContactPersonModalProps) => {
    const showToast = useToast();
    const { l } = useLocale();
    const showGenericErrorToast = useGenericErrorToast();
    const editTenantContactPerson = usePutV1ContactPersonAdminContactPersonId();
    const queryClient = useQueryClient();

    if (props.contactPerson === undefined) {
        showToast(
            l('_error'),
            l('_unableToRetrieveInfoForContactPerson'),
            'error'
        );
        props.setNotVisible();
    }

    const putContactPersonRequest = (
        contactPersonId: number,
        values: EditContactPersonRequest
    ) => {
        if (props.contactPerson === undefined) {
            return; // do not expect this to ever occur
        }
        editTenantContactPerson.mutate(
            { contactPersonId, data: values },
            {
                onSuccess: () => {
                    showToast(
                        l('_success'),
                        l('_contactPersonUpdatedSuccessfully'),
                        'success'
                    );
                    props.setNotVisible();
                    queryClient.invalidateQueries(
                        getGetV1TenantSuperAdminQueryKey()
                    );
                    queryClient.invalidateQueries(
                        getGetV1ContactPersonTenantTenantIdQueryKey(
                            props.tenantId
                        )
                    );
                },
                onError: (
                    error: PutV1ContactPersonAdminContactPersonIdMutationError
                ) => {
                    showGenericErrorToast(error);
                },
            }
        );
    };

    return (
        <>
            {props.isVisible && (
                <ModalContainer isVisible={props.isVisible}>
                    <Modal
                        visible
                        closable
                        closeAriaLabel={l('_close')}
                        title={l('_editContactPerson')}
                        aria-label={l('_editContactPerson')}
                        modalStyle={{
                            width: '50vw',
                            maxWidth: '500px',
                            minWidth: '250px',
                        }}
                        closeOnPressEscape
                        onCancel={() => props.setNotVisible()}
                    >
                        <EditContactPersonForm
                            submitFunc={putContactPersonRequest}
                            currentContactPersonValues={
                                props.contactPerson as ContactPersonResponse
                            } // able to cast as ensured not undefined at start
                        />
                    </Modal>
                </ModalContainer>
            )}
        </>
    );
};

export default EditContactPersonModal;
