import { Avatar } from '@appkit4/react-components/avatar';
import { DropdownButton, ValueType } from '@appkit4/react-components/dropdown';
import { ItemDataType } from '@appkit4/react-components/filter';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import React, { SyntheticEvent, useState, useEffect } from 'react';
import { AppRoutes } from '../../Constants';
import Roles from '../../auth/roles';
import useLocale from '../../locale/useLocale';
import { useGetV1IdentityUserInfo } from '../../api/generated/endpoint';

const AvatarDropdown = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const { l } = useLocale();
    const [currentSelect, setCurrentSelect] = useState('' as ValueType);

    const handleSelect = (
        value: ValueType,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        _item: ItemDataType,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        _event: SyntheticEvent<Element, Event>
    ) => {
        navigate(`${value}`);
    };

    const { data: userData } = useGetV1IdentityUserInfo({
        query: {
            staleTime: Infinity,
        },
    });

    useEffect(() => {
        if (location.pathname === AppRoutes.SettingsPage) {
            setCurrentSelect(AppRoutes.SettingsPage);
        } else if (location.pathname === AppRoutes.ContactPage) {
            setCurrentSelect(AppRoutes.ContactPage);
        } else if (location.pathname === AppRoutes.Management) {
            setCurrentSelect(AppRoutes.Management);
        } else {
            setCurrentSelect('');
        }
    }, [location.pathname]);

    const data = [
        {
            label: l('_settings'),
            value: AppRoutes.SettingsPage,
            disabled: false,
            iconName: 'setting-outline',
        },
        {
            label: l('_contactUsTitleCase'),
            value: AppRoutes.ContactPage,
            disabled: false,
            iconName: 'help-question-outline',
        },
        {
            label: l('_logout'),
            value: AppRoutes.LogoutPage,
            disabled: false,
            iconName: 'log-out-outline',
        },
    ];

    if (
        userData?.roleId === Roles.AdminId ||
        userData?.roleId === Roles.SuperAdminId
    ) {
        data.splice(0, 0, {
            label:
                userData?.roleId === Roles.SuperAdminId
                    ? l('_adminPanelTitleCase') // super admin users have access to multiple features (management + application policies)
                    : l('_management'), // normal admin users only have access to management
            value: AppRoutes.Management,
            disabled: false,
            iconName: 'audience-outline',
        });
    }

    if (userData?.roleId === Roles.SuperAdminId) {
        data.splice(1, 0, {
            label: l('_businessMetricsTitleCase'),
            value: AppRoutes.BusinessMetrics,
            disabled: false,
            iconName: 'powerbi-outline',
        });
    }

    const getInitials = (): string => {
        let initials = '';
        initials += auth.user?.profile.given_name?.charAt(0) || '?';
        initials += auth.user?.profile.family_name?.charAt(0) || '?';
        return initials;
    };

    const prefixTemplate = (
        item: ItemDataType
    ): React.ReactNode | undefined => {
        if (item.iconName) {
            return (
                <span
                    className={`Appkit4-icon icon-${item.iconName as string}`}
                />
            );
        }
        return undefined;
    };

    const initials: string = getInitials();

    return (
        <DropdownButton
            data={data}
            splitButton={false}
            customTriggerClassName="custom-node"
            align="right"
            onSelect={handleSelect}
            customTriggerNode
            prefixTemplate={prefixTemplate}
            value={currentSelect}
        >
            <Avatar
                className={'avatar-cursor'}
                label={initials}
                ariaLabel={l('_navigationMenu')}
                disabled={true}
            />
        </DropdownButton>
    );
};

export default AvatarDropdown;
