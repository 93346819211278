import React from 'react';
import { Modal } from '@appkit4/react-components/modal';
import useGenericErrorToast from '../../../../../helpers/useGenericErrorToast';
import CreateUserForm from './CreateUserForm';
import { FormValues } from './CreateUserForm';
import useToast from '../../../../../helpers/useToast';
import useLocale from '../../../../../locale/useLocale';
import ModalContainer from '../../../../ModalContainer';

import {
    PutV1UserAdminUserIdMutationError,
    getGetV1TenantSuperAdminQueryKey,
    getGetV1UserAdminQueryKey,
    useGetV1IdentityUserInfo,
    useGetV1RoleAdminAll,
    usePostV1UserAdmin,
} from '../../../../../api/generated/endpoint';
import { useQueryClient } from '@tanstack/react-query';

interface CreateUserModalProps {
    isVisible: boolean;
    setIsVisible: (display: boolean) => void;
    currentTenantId: number;
}

const CreateUserModal = (props: CreateUserModalProps) => {
    const showToast = useToast();
    const { l } = useLocale();
    const showGenericErrorToast = useGenericErrorToast();
    const createNewUser = usePostV1UserAdmin();
    const queryClient = useQueryClient();

    const { data: roles } = useGetV1RoleAdminAll({
        query: {
            onSuccess: (data) => {
                if (!data || data.length < 1) {
                    showToast(
                        l('_error'),
                        l('_noRolesFoundUnableToCreateAUserRightNow'),
                        'error'
                    );
                    props.setIsVisible(false);
                }
            },
            onError: (error) => {
                showGenericErrorToast(error);
                props.setIsVisible(false);
            },
            staleTime: Infinity,
        },
    });

    const { data: userInfo } = useGetV1IdentityUserInfo({
        query: {
            staleTime: Infinity,
        },
    });

    const postNewUserRequest = (values: FormValues) => {
        createNewUser.mutate(
            {
                data: {
                    email: values.email,
                    surname: values.surname,
                    forenames: values.forenames,
                    roleId: values.roleId,
                    tenantId: props.currentTenantId,
                },
            },
            {
                onSuccess: () => {
                    showToast(l('_success'), l('_newUserCreated'), 'success');
                    props.setIsVisible(false);
                    queryClient.invalidateQueries(getGetV1UserAdminQueryKey());
                    queryClient.invalidateQueries(
                        getGetV1TenantSuperAdminQueryKey()
                    );
                },
                onError: (error: PutV1UserAdminUserIdMutationError) => {
                    showGenericErrorToast(error);
                    props.setIsVisible(false);
                },
            }
        );
    };

    return (
        <>
            {props.isVisible && (
                <ModalContainer isVisible={props.isVisible}>
                    <Modal
                        visible
                        closable
                        closeAriaLabel={l('_close')}
                        title={l('_createNewUser')}
                        aria-label={l('_createANewUser')}
                        modalStyle={{
                            width: '50vw',
                            maxWidth: '500px',
                            minWidth: '250px',
                        }}
                        closeOnPressEscape
                        onCancel={() => props.setIsVisible(false)}
                    >
                        <CreateUserForm
                            roles={
                                // filter roles to only allow roles up to and including current user's role
                                roles?.filter(
                                    (r) => r.id <= (userInfo?.roleId ?? -1)
                                ) ?? []
                            }
                            submitFunc={postNewUserRequest}
                        />
                    </Modal>
                </ModalContainer>
            )}
        </>
    );
};

export default CreateUserModal;
