import React, { useState, FC } from 'react';
import { Table, Column } from '@appkit4/react-components/table';
import { Loading } from '@appkit4/react-components/loading';
import { Search } from '@appkit4/react-components/search';
import { Button } from '@appkit4/react-components/button';
import { Tooltip } from '@appkit4/react-components/tooltip';
import { Pagination } from '@appkit4/react-components/pagination';
import { Select } from '@appkit4/react-components/select';
import useGenericErrorToast from '../../../../../helpers/useGenericErrorToast';
import { useGetV1UserAdmin } from '../../../../../api/generated/endpoint';
import usePaginationControl from '../../../../../helpers/usePaginationControl';
import { UserResponse } from '../../../../../api/generated/models';
import useLocale from '../../../../../locale/useLocale';
import CreateUserModal from './CreateUserModal';
import EditUserModal from './EditUserModal';
import ConfirmDeleteUserModal from './ConfirmDeleteUserModal';
import ConfirmRestoreUserModal from './ConfirmRestoreUserModal';
import UserActionsCell from './UserActionsCell';

interface UsersTableProps {
    selectedTenantId: number;
    title?: string;
}

export const enum UserStatuses {
    Active = 1,
    Deleted,
}

const UsersTable: FC<UsersTableProps> = (props) => {
    const { l } = useLocale();

    const userStatuses = [
        { label: l('_active'), value: UserStatuses.Active },
        { label: l('_deleted'), value: UserStatuses.Deleted },
    ];
    const [showDeleteConfirmationForId, setShowDeleteConfirmationForId] =
        useState<number | null>(null);
    const [showRestoreConfirmationForId, setShowRestoreConfirmationForId] =
        useState<number | null>(null);
    const [displayCreateUserModal, setDisplayCreateUserModal] = useState(false);
    const [displayEditUserModalForId, setDisplayEditUserModalForId] = useState<
        number | null
    >(null);
    const [searchString, setSearchString] = useState<string>('');
    const [userStatus, setUserStatus] = useState<number>(UserStatuses.Active);
    const pageSize = 20;
    const [pageNum, setPageNum] = useState<number>(1);
    const skip = (pageNum - 1) * pageSize;
    const take = pageSize;
    const showGenericErrorToast = useGenericErrorToast();

    /* Load info for all users of current tenant (or selected tenant in case of super admin) */
    const { data: paginatedUserResponse, isLoading: loadingUsers } =
        useGetV1UserAdmin(
            {
                skip,
                take,
                searchString,
                tenantId: props.selectedTenantId,
                userStatus,
            },
            {
                query: {
                    onError: showGenericErrorToast,
                },
            }
        );

    /* prevent current page number being invalid */
    usePaginationControl(
        { totalCount: paginatedUserResponse?.totalUserCount },
        pageSize,
        setPageNum,
        pageNum
    );

    let typingTimer: NodeJS.Timeout; //timer identifier
    const doneTypingIntervalms = 1000; //time in ms

    const renderTooltip = (row: UserResponse, field: keyof UserResponse) => {
        return (
            <Tooltip
                trigger="hover"
                position="top"
                distance={5}
                id={`tooltip-${field}`}
                content={`${row[field]}`}
                style={{
                    wordBreak: 'break-word',
                    overflowWrap: 'break-word',
                    whiteSpace: 'normal',
                }}
            >
                <span>{row[field]}</span>
            </Tooltip>
        );
    };

    const actionsColumnStyle: React.CSSProperties = {
        width: '6rem',
    };

    return (
        <>
            <div className="row">
                <div className="col-8">
                    <h2 className="ap-typography-heading-s">{props.title}</h2>
                </div>
                {((!loadingUsers && paginatedUserResponse) ||
                    searchString != '') && (
                    <div className="col-8 d-flex">
                        <Search
                            id="user-search"
                            searchType="primary"
                            placeholder={l('_search')}
                            onSearch={(searchString) =>
                                setSearchString(searchString)
                            }
                            onChange={(query: string, event) => {
                                clearTimeout(typingTimer);
                                if (!query) {
                                    setSearchString('');
                                } else {
                                    typingTimer = setTimeout(() => {
                                        // simulate pressing enter on the search box
                                        // so the onSearch method handles all the actual searching
                                        event.target.dispatchEvent(
                                            new KeyboardEvent('keydown', {
                                                code: 'Enter',
                                                key: 'Enter',
                                                charCode: 13,
                                                keyCode: 13,
                                                view: window,
                                                bubbles: true,
                                            })
                                        );
                                    }, doneTypingIntervalms);
                                }
                            }}
                            onClear={() => setSearchString('')}
                            className="m-0 p-0 pe-2"
                        />
                        <div>
                            <Select
                                data={userStatuses}
                                searchable={false}
                                defaultValue={userStatus}
                                placeholder={'User status'}
                                onSelect={(selected) =>
                                    setUserStatus(Number(selected))
                                }
                                data-testid="user-status-select"
                            />
                        </div>
                    </div>
                )}
                <div className="col-4 d-flex align-items-center">
                    <Button
                        kind="primary"
                        icon="icon-plus-outline"
                        type="button"
                        role="button"
                        onClick={() => setDisplayCreateUserModal(true)}
                        className="ms-auto d-flex align-items-center btn-max-height"
                    >
                        {l('_addUser')}
                    </Button>
                </div>
            </div>

            {loadingUsers ? (
                <Loading
                    loadingType="circular"
                    indeterminate
                    compact={false}
                    className="mt-2"
                />
            ) : paginatedUserResponse &&
              paginatedUserResponse?.users.length > 0 ? (
                <>
                    <Table
                        className="ap-mt-spacing-4 admin-table-container"
                        originalData={paginatedUserResponse?.users}
                        condensed
                        striped
                        hasTitle
                    >
                        <Column
                            field="forenames"
                            renderCell={(row) =>
                                renderTooltip(row, 'forenames')
                            }
                        >
                            {l('_forename(s)')}
                        </Column>
                        <Column
                            field="surname"
                            renderCell={(row) => renderTooltip(row, 'surname')}
                        >
                            {l('_surname')}
                        </Column>
                        <Column
                            field="email"
                            renderCell={(row) => renderTooltip(row, 'email')}
                        >
                            {l('_email')}
                        </Column>
                        <Column
                            field="roleName"
                            renderCell={(row) => renderTooltip(row, 'roleName')}
                        >
                            {l('_role')}
                        </Column>
                        <Column
                            renderCell={(row: UserResponse) => (
                                <UserActionsCell
                                    user={row}
                                    userStatus={userStatus}
                                    displayEditUserModal={() =>
                                        setDisplayEditUserModalForId(row.id)
                                    }
                                    displayDeleteUserModal={() =>
                                        setShowDeleteConfirmationForId(row.id)
                                    }
                                    displayRestoreUserModal={() =>
                                        setShowRestoreConfirmationForId(row.id)
                                    }
                                />
                            )}
                            field="id"
                            style={actionsColumnStyle}
                        >
                            {l('_actions')}
                        </Column>
                    </Table>

                    <Pagination
                        data-testid="pagination"
                        total={Math.ceil(
                            paginatedUserResponse.totalUserCount / pageSize
                        )}
                        current={pageNum}
                        onPageChange={(page: number) => setPageNum(page)}
                        style={{ float: 'right' }}
                    />
                </>
            ) : (
                <Table
                    className="ap-mt-spacing-4 text-center admin-table-container"
                    originalData={{
                        message: (
                            <p className="m-0 error-text-heading">
                                {searchString == ''
                                    ? l('_noUsersFound')
                                    : l('_noSearchResultsFound')}
                            </p>
                        ),
                    }}
                >
                    <Column field="message" />
                </Table>
            )}

            {displayCreateUserModal && (
                <CreateUserModal
                    isVisible={displayCreateUserModal}
                    setIsVisible={setDisplayCreateUserModal}
                    currentTenantId={props.selectedTenantId}
                />
            )}

            {displayEditUserModalForId && (
                <EditUserModal
                    isVisible={displayEditUserModalForId !== null}
                    setNotVisible={() => setDisplayEditUserModalForId(null)}
                    user={paginatedUserResponse?.users.find(
                        (u) => u.id == displayEditUserModalForId
                    )}
                />
            )}

            {showDeleteConfirmationForId && (
                <ConfirmDeleteUserModal
                    visible={showDeleteConfirmationForId !== null}
                    userInfo={paginatedUserResponse?.users.find(
                        (u) => u.id == showDeleteConfirmationForId
                    )}
                    setNotVisible={() => setShowDeleteConfirmationForId(null)}
                />
            )}

            {showRestoreConfirmationForId && (
                <ConfirmRestoreUserModal
                    visible={showRestoreConfirmationForId !== null}
                    userInfo={paginatedUserResponse?.users.find(
                        (u) => u.id == showRestoreConfirmationForId
                    )}
                    setNotVisible={() => setShowRestoreConfirmationForId(null)}
                />
            )}
        </>
    );
};

export default UsersTable;
