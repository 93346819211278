import React from 'react';
import { Modal } from '@appkit4/react-components/modal';
import useGenericErrorToast from '../../../../../helpers/useGenericErrorToast';
import useToast from '../../../../../helpers/useToast';
import EditContactPersonForm from '../../../../EditContactPersonForm';
import useLocale from '../../../../../locale/useLocale';
import {
    PutV1ContactPersonAdminContactPersonIdMutationError,
    getGetV1ContactPersonApplicationQueryKey,
    usePutV1ContactPersonAdminContactPersonId,
} from '../../../../../api/generated/endpoint';
import { useQueryClient } from '@tanstack/react-query';
import {
    EditContactPersonRequest,
    ContactPersonResponse,
} from '../../../../../api/generated/models';

interface EditApplicationContactModalProps {
    applicationContactToEdit: ContactPersonResponse;
    isVisible: boolean;
    setNotVisible: () => void;
}

const EditApplicationContactModal = (
    props: EditApplicationContactModalProps
) => {
    const showToast = useToast();
    const { l } = useLocale();
    const showGenericErrorToast = useGenericErrorToast();
    const queryClient = useQueryClient();

    const editApplicationContactRequest =
        usePutV1ContactPersonAdminContactPersonId({
            mutation: {
                onSuccess: () => {
                    showToast(
                        l('_success'),
                        l('_applicationcContactUpdated'),
                        'success'
                    );
                    queryClient.invalidateQueries(
                        getGetV1ContactPersonApplicationQueryKey()
                    );
                    props.setNotVisible();
                },
                onError: (
                    error: PutV1ContactPersonAdminContactPersonIdMutationError
                ) => {
                    showGenericErrorToast(error);
                    props.setNotVisible();
                },
            },
        });

    const putEditApplicationContactRequest = (
        applicationContactId: number,
        editedApplicationContact: EditContactPersonRequest
    ) => {
        editApplicationContactRequest.mutate({
            contactPersonId: applicationContactId,
            data: editedApplicationContact,
        });
    };

    return (
        <>
            {props.isVisible && (
                <Modal
                    visible
                    closable
                    closeAriaLabel={l('_close')}
                    title={l('_editApplicationContact')}
                    aria-label={l('_editApplicationContact')}
                    modalStyle={{
                        width: '50vw',
                        maxWidth: '500px',
                        minWidth: '250px',
                    }}
                    footerStyle={{ marginTop: '1rem' }}
                    closeOnPressEscape
                    onCancel={() => props.setNotVisible()}
                >
                    <EditContactPersonForm
                        currentContactPersonValues={
                            props.applicationContactToEdit
                        }
                        submitFunc={putEditApplicationContactRequest}
                    />
                </Modal>
            )}
        </>
    );
};

export default EditApplicationContactModal;
