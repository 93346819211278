import React, { useState } from 'react';
import { Modal } from '@appkit4/react-components/modal';
import { Button } from '@appkit4/react-components/button';
import useGenericErrorToast from '../../../../../helpers/useGenericErrorToast';
import useToast from '../../../../../helpers/useToast';
import { TenantResponse } from '../../../../../api/generated/models';
import useLocale from '../../../../../locale/useLocale';
import ModalContainer from '../../../../ModalContainer';
import {
    PutV1TenantSuperAdminRestoreTenantIdMutationError,
    usePutV1TenantSuperAdminRestoreTenantId,
} from '../../../../../api/generated/endpoint';
import { AppRoutes } from '../../../../../Constants';
import { useNavigate } from 'react-router-dom';
import { Checkbox } from '@appkit4/react-components/checkbox';

interface ConfirmRestoreTenantModalProps {
    visible: boolean;
    tenantInfo: TenantResponse | undefined;
    setNotVisible: () => void;
}

const ConfirmRestoreTenantModal = (props: ConfirmRestoreTenantModalProps) => {
    const showToast = useToast();
    const { l } = useLocale();
    const showGenericErrorToast = useGenericErrorToast();
    const restoreTenant = usePutV1TenantSuperAdminRestoreTenantId();
    const navigate = useNavigate();
    const [restoreUsers, setRestoreUsers] = useState<boolean>(false);

    const handleConfirmRestore = () => {
        restoreTenant.mutate(
            {
                tenantId: props.tenantInfo?.id ?? -1,
                params: {
                    restoreUsers,
                },
            },
            {
                onSuccess: () => {
                    showToast(l('_success'), l('_tenantRestored'), 'success');
                    props.setNotVisible();
                    navigate(`${AppRoutes.Tenant}/${props.tenantInfo?.id}`);
                },
                onError: (
                    error: PutV1TenantSuperAdminRestoreTenantIdMutationError
                ) => {
                    showGenericErrorToast(error);
                    props.setNotVisible();
                },
            }
        );
    };

    return (
        <>
            {props.visible && (
                <ModalContainer isVisible={props.visible}>
                    <Modal
                        visible
                        closable
                        closeAriaLabel={l('_close')}
                        title={l('_confirmTenantRestore')}
                        aria-label={`${l('_confirmTenantRestoreWithName')} ${
                            props.tenantInfo?.name ?? '-'
                        }`}
                        onCancel={props.setNotVisible}
                        modalStyle={{
                            width: '50vw',
                            maxWidth: '500px',
                            minWidth: '250px',
                        }}
                        footerStyle={{ marginTop: '1rem' }}
                        footer={
                            <>
                                <Button
                                    kind="secondary"
                                    onClick={props.setNotVisible}
                                    className={'focus-margin'}
                                >
                                    {l('_cancel')}
                                </Button>
                                <Button
                                    kind="primary"
                                    onClick={() => handleConfirmRestore()}
                                    className={'focus-margin'}
                                >
                                    {l('_restore')}
                                </Button>
                            </>
                        }
                    >
                        <p>{l('_areYouSureYouWantToRestoreTenant')}</p>
                        <div className="row mt-2">
                            <div className="col">
                                {props.tenantInfo?.name ?? '-'}
                            </div>
                        </div>
                        <div className="row mt-4">
                            <Checkbox
                                checked={restoreUsers}
                                onChange={(checked: boolean) =>
                                    setRestoreUsers(checked)
                                }
                            >
                                {l('_restoreAllDeletedUsersCheckbox')}
                            </Checkbox>
                        </div>
                        <p className="disclaimer-text">
                            {l('_everyAssociatedUserWillBeRestored')}
                        </p>
                    </Modal>
                </ModalContainer>
            )}
        </>
    );
};

export default ConfirmRestoreTenantModal;
