import React, { useState } from 'react';

import ConfirmDeleteContactPersonModal from '../../../../ConfirmDeleteContactPersonModal';
import useLocale from '../../../../../locale/useLocale';
import useGenericErrorToast from '../../../../../helpers/useGenericErrorToast';
import { useGetV1ContactPersonTenantTenantId } from '../../../../../api/generated/endpoint';
import { Button, Column, Loading, Table } from '@appkit4/react-components';
import CreateTenantContactModal from './CreateTenantContactModal';
import EditContactPersonModal from './EditTenantContactModal';
import TenantContactsActionsCell from './TenantContactsActionsCell';
import { ContactPersonResponse } from '../../../../../api/generated/models';

export interface TenantContactsTableProps {
    tenantId: number;
}

const TenantContactsTable = (props: TenantContactsTableProps) => {
    const { l } = useLocale();
    const showGenericErrorToast = useGenericErrorToast();

    const [
        displayDeleteContactPersonConfirmationForId,
        setDisplayDeleteContactPersonConfirmationForId,
    ] = useState<number | null>(null);

    const [
        displayEditContactPersonModalForId,
        setDisplayEditContactPersonModalForId,
    ] = useState<number | null>(null);

    const [
        displayCreateContactPersonModal,
        setDisplayCreateContactPersonModal,
    ] = useState(false);

    const { data: tenantContacts, isLoading: isLoadingTenantContacts } =
        // The query is only enabled when the tenantId is not null
        useGetV1ContactPersonTenantTenantId(props.tenantId, {
            query: {
                onError: showGenericErrorToast,
            },
        });

    return (
        <>
            <div className="row">
                <div className="col">
                    <h2 className="ap-typography-heading-s my-2">
                        {l('_tenantContacts')}
                    </h2>
                </div>
                <div className="col d-flex align-items-center">
                    <Button
                        kind="primary"
                        icon="icon-plus-outline"
                        type="button"
                        role="button"
                        onClick={() => setDisplayCreateContactPersonModal(true)}
                        className="ms-auto d-flex align-items-center btn-max-height"
                    >
                        {l('_newContactPerson')}
                    </Button>
                </div>
            </div>

            {isLoadingTenantContacts ? (
                <Loading loadingType="circular" indeterminate compact={false} />
            ) : tenantContacts && tenantContacts?.length > 0 ? (
                <>
                    <Table
                        className="ap-mt-spacing-4 contacts-table-container admin-table-container"
                        originalData={tenantContacts}
                        condensed
                        striped
                        hasTitle
                    >
                        <Column field="forenames">{l('_forename(s)')}</Column>
                        <Column field="surname">{l('_surname')}</Column>
                        <Column field="email">{l('_email')}</Column>
                        <Column
                            renderCell={(row: ContactPersonResponse) => (
                                <TenantContactsActionsCell
                                    user={row}
                                    displayEditContactModal={() =>
                                        setDisplayEditContactPersonModalForId(
                                            row.id
                                        )
                                    }
                                    displayDeleteContactModal={() =>
                                        setDisplayDeleteContactPersonConfirmationForId(
                                            row.id
                                        )
                                    }
                                />
                            )}
                            field="id"
                            style={{ width: '6rem' }}
                        >
                            {l('_actions')}
                        </Column>
                    </Table>
                    {displayEditContactPersonModalForId && (
                        <EditContactPersonModal
                            isVisible={Boolean(
                                displayEditContactPersonModalForId
                            )}
                            setNotVisible={() => {
                                setDisplayEditContactPersonModalForId(null);
                            }}
                            contactPerson={tenantContacts?.find(
                                (c) =>
                                    c.id == displayEditContactPersonModalForId
                            )}
                            tenantId={props.tenantId}
                        />
                    )}

                    {displayDeleteContactPersonConfirmationForId && (
                        <ConfirmDeleteContactPersonModal
                            visible={Boolean(
                                displayDeleteContactPersonConfirmationForId
                            )}
                            contactPerson={tenantContacts?.find(
                                (c) =>
                                    c.id ==
                                    displayDeleteContactPersonConfirmationForId
                            )}
                            setNotVisible={() => {
                                setDisplayDeleteContactPersonConfirmationForId(
                                    null
                                );
                            }}
                            tenantId={props.tenantId}
                        />
                    )}
                </>
            ) : (
                <Table
                    className="ap-mt-spacing-4 text-center admin-table-container"
                    originalData={{
                        message: (
                            <p className="m-0 error-text-heading">
                                {l('_noTenantContactsFound')}
                            </p>
                        ),
                    }}
                >
                    <Column field="message" />
                </Table>
            )}

            {displayCreateContactPersonModal && (
                <CreateTenantContactModal
                    isVisible={displayCreateContactPersonModal}
                    setNotVisible={() =>
                        setDisplayCreateContactPersonModal(false)
                    }
                    tenantId={props.tenantId}
                />
            )}
        </>
    );
};

export default TenantContactsTable;
