import { useParams } from 'react-router';
import {
    PutV1TenantSuperAdminTenantIdMutationError,
    useGetV1TenantSuperAdminTenantId,
    getGetV1TenantSuperAdminTenantIdQueryKey,
    usePutV1TenantSuperAdminTenantId,
    useGetV1IdentityUserInfo,
} from '../../api/generated/endpoint';
import useGenericErrorToast from '../../helpers/useGenericErrorToast';
import { useState } from 'react';

import useToast from '../../helpers/useToast';
import useLocale from '../../locale/useLocale';
import { useQueryClient } from '@tanstack/react-query';
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Loading,
} from '@appkit4/react-components';
import './AdminPanel.scss';
import RenameTenantForm, {
    FormValues,
} from '../../components/pages/admin/tenant/superAdmin/RenameTenantForm';
import UsersTable from '../../components/pages/admin/management/shared/UsersTable';
import useTitle from '../../helpers/useTitle';
import TenantContactsTable from '../../components/pages/admin/tenant/superAdmin/TenantContactsTable';
import { AppRoutes } from '../../Constants';
import ConfirmDeleteTenantModal from '../../components/pages/admin/tenant/superAdmin/ConfirmDeleteTenantModal';

const Tenant = () => {
    const showToast = useToast();
    const { l } = useLocale();
    const showGenericErrorToast = useGenericErrorToast();
    const renameTenantRequest = usePutV1TenantSuperAdminTenantId();
    const queryClient = useQueryClient();
    useTitle(l('_management'));

    const { tenantId } = useParams();

    const tenantIdNum = Number(tenantId);

    const [editingTenantName, setEditingTenantName] = useState(false);

    const [displayDeleteTenantModal, setDisplayDeleteTenantModal] =
        useState<boolean>(false);

    const { data: userInfo } = useGetV1IdentityUserInfo({
        query: {
            staleTime: Infinity,
        },
    });

    const { data: tenant, isLoading: loadingTenant } =
        useGetV1TenantSuperAdminTenantId(tenantIdNum, {
            query: {
                onError: showGenericErrorToast,
            },
        });

    const putRenameTenantRequest = (values: FormValues) => {
        renameTenantRequest.mutate(
            {
                tenantId: tenantIdNum,
                data: { name: values.name },
            },
            {
                onSuccess: () => {
                    showToast(
                        l('_success'),
                        l('_tenantNameUpdated'),
                        'success'
                    );
                    queryClient.invalidateQueries(
                        getGetV1TenantSuperAdminTenantIdQueryKey(tenantIdNum)
                    );
                },
                onError: (
                    error: PutV1TenantSuperAdminTenantIdMutationError
                ) => {
                    showGenericErrorToast(error);
                },
            }
        );
    };

    return (
        <>
            {loadingTenant && !isNaN(tenantIdNum) ? (
                <Loading
                    loadingType="circular"
                    indeterminate
                    compact={false}
                    className="mt-2"
                />
            ) : tenant ? (
                <>
                    <Breadcrumb className="breadcrumb">
                        <BreadcrumbItem>
                            <a
                                href={AppRoutes.Management}
                                className="breadcrumb-link"
                            >
                                {l('_management')}
                            </a>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <span>{tenant.name}</span>
                        </BreadcrumbItem>
                    </Breadcrumb>
                    {!editingTenantName && (
                        <div className="row mt-3">
                            <div className="col-auto">
                                <h1 className="mb-0">{tenant.name}</h1>
                            </div>
                            <div className="col ps-0 d-flex align-items-center">
                                <Button
                                    className="p-0 edit-tenant-name-button d-flex align-items-center justify-content-center"
                                    icon="icon-edit-outline"
                                    type="button"
                                    role="button"
                                    onClick={() => setEditingTenantName(true)}
                                    title={l('_renameTenant')}
                                />
                            </div>
                            <div className="col-auto d-flex align-items-center">
                                <Button
                                    kind="secondary"
                                    className="ms-auto d-flex align-items-center justify-content-center"
                                    icon="icon-delete-outline"
                                    type="button"
                                    role="button"
                                    onClick={() =>
                                        setDisplayDeleteTenantModal(true)
                                    }
                                    disabled={userInfo?.tenantId == tenantId}
                                >
                                    {l('_deleteTenant')}
                                </Button>
                            </div>
                        </div>
                    )}

                    {editingTenantName && (
                        <RenameTenantForm
                            closeFunc={() => setEditingTenantName(false)}
                            submitFunc={putRenameTenantRequest}
                            initialName={tenant.name}
                        />
                    )}

                    <div className="mt-4">
                        <UsersTable
                            selectedTenantId={tenant.id}
                            title={l('_tenantUsers')}
                        />
                    </div>

                    <div style={{ marginTop: '5.5rem' }}>
                        <TenantContactsTable tenantId={tenantIdNum} />
                    </div>

                    {displayDeleteTenantModal && (
                        <ConfirmDeleteTenantModal
                            visible
                            tenant={tenant}
                            setNotVisible={() =>
                                setDisplayDeleteTenantModal(false)
                            }
                        />
                    )}
                </>
            ) : (
                <p className="text-center mt-2 error-text-heading">
                    {l('_noTenantFound')}
                </p>
            )}
        </>
    );
};

export default Tenant;
