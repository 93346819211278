import { Button } from '@appkit4/react-components';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Input } from '@appkit4/react-components/field';
import useLocale from '../../../../../locale/useLocale';

interface CreateTenantFormProps {
    submitFunc: (values: FormValues) => void;
}

export interface FormValues {
    name: string;
}

const CreateTenantForm = (props: CreateTenantFormProps) => {
    const { l } = useLocale();

    const NewTenantSchema = Yup.object({
        name: Yup.string()
            .trim()
            .max(100, l('_pleaseEnterFewerCharacters'))
            .required(l('_pleaseEnterATenantName')),
    });

    return (
        <Formik
            initialValues={{
                name: '',
            }}
            validationSchema={NewTenantSchema}
            onSubmit={(values) => props.submitFunc(values)}
        >
            {({
                errors,
                touched,
                setFieldTouched,
                setFieldValue,
                validateField,
            }) => (
                <Form className="pt-1">
                    <Input
                        type="text"
                        title={l('_tenantName')}
                        placeholder={l('_tenantName')}
                        required
                        onChange={(value: string) => {
                            setFieldTouched('name');
                            validateField('name');
                            setFieldValue('name', value);
                        }}
                    />

                    {touched.name && errors.name && (
                        <div className="error-text">{errors.name}</div>
                    )}

                    <Button className="ms-auto mt-5" type="submit">
                        {l('_createNewTenant')}
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

export default CreateTenantForm;
