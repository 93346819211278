import { Notification, toaster } from '@appkit4/react-components/notification';
import React from 'react';

type ToastPositions = 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
type ToastTypes = 'error' | 'success';

const useToast =
    () =>
    (
        title: string,
        message: string,
        status: ToastTypes,
        position?: ToastPositions,
        duration?: number
    ): void => {
        const ele = (
            <Notification title={title} message={message} status={status} />
        );

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        toaster.notify(ele, {
            position: position || 'topRight',
            duration: duration || 5000,
        });
    };

export default useToast;
