// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import { AppRoutes } from '../../Constants';

export const Timeout = () => {
    const navigate = useNavigate();
    const onIdle = () => {
        navigate(AppRoutes.LogoutPage);
    };

    useIdleTimer({
        onIdle,
        timeout:
            1000 *
            +(
                process.env.REACT_APP_AUTH_INACTIVITY_LOGOUT_TIMEOUT_SECONDS ??
                1800
            ),
    });

    return null;
};

export default Timeout;
