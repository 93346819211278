import React from 'react';
import { Avatar, AvatarGroup } from '@appkit4/react-components/avatar';
import { Tooltip } from '@appkit4/react-components/tooltip';
import { SuperAdminTenantResponse } from '../../../../../api/generated/models';
import useWindowWidth from '../../../../../helpers/useWindowWidth';

export interface TenantAdminsCellProps {
    tenant: SuperAdminTenantResponse;
}

const TenantAdminsCell = (props: TenantAdminsCellProps) => {
    const avatarColors = [
        'ap-bg-primary-red-05',
        'ap-bg-primary-orange-05',
        'ap-bg-primary-pink-05',
        'ap-bg-primary-teal-07',
        'ap-bg-primary-blue-04',
    ];
    const windowWidth = useWindowWidth();
    const narrowScreenWidth = 575;
    const maxAvatars = windowWidth < narrowScreenWidth ? 3 : 5;

    const extraAvatarsCount = props.tenant.admins
        ? props.tenant.admins.length - maxAvatars
        : 0;
    const extraAvatarsNames = props.tenant.admins
        ? props.tenant.admins
              .slice(maxAvatars)
              .map((admin) => `${admin.forenames} ${admin.surname}`)
              .join(', ')
        : '';

    const avatarStyle = {
        width: '23px',
        height: '23px',
        fontSize: '11px',
        fontWeight: 'normal',
        padding: '0',
    };

    const divStyle = {
        height: '26px',
        display: 'flex',
        alignItems: 'center',
    };

    const avatars: JSX.Element[] = props.tenant.admins
        ? props.tenant.admins.slice(0, maxAvatars).map((admin, index) => {
              const avatarBgColor = avatarColors[index % avatarColors.length];

              return (
                  <div key={admin.email}>
                      {/* display full name when hover over avatar */}
                      <Tooltip
                          trigger="hover"
                          position="top"
                          distance={5}
                          id={`${index}`}
                          content={`${admin.forenames} ${admin.surname}`}
                      >
                          <Avatar
                              label={`${admin.forenames
                                  .charAt(0)
                                  .toUpperCase()}${admin.surname
                                  .charAt(0)
                                  .toUpperCase()}`}
                              style={avatarStyle}
                              className={avatarBgColor}
                          />
                      </Tooltip>
                  </div>
              );
          })
        : [<></>];

    /* display +{extra Avatars} if more than 0 */
    if (extraAvatarsCount > 0) {
        avatars.push(
            <div key={maxAvatars}>
                <Tooltip
                    trigger="hover"
                    position="right"
                    distance={5}
                    id={`${maxAvatars}`}
                    content={extraAvatarsNames}
                >
                    <Avatar
                        label={`+${extraAvatarsCount}`}
                        style={avatarStyle}
                        className="ap-bg-neutral-13"
                    />
                </Tooltip>
            </div>
        );
    }

    return (
        <div className="col" style={divStyle}>
            <AvatarGroup>{avatars}</AvatarGroup>
        </div>
    );
};

export default TenantAdminsCell;
