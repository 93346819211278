import appkitVariables from '../../../../../styles/appkit-variables.module.scss';

export const DataVisualisationColors = [
    appkitVariables.dataorangelighter,
    appkitVariables.dataorangelight,
    appkitVariables.datatangerinelighter,
    appkitVariables.datatangerinelight,
    appkitVariables.datatangerine,
    appkitVariables.datayellowlighter,
    appkitVariables.datayellowlight,
    appkitVariables.datayellow,
    appkitVariables.dataredlighter,
    appkitVariables.dataredlight,
    appkitVariables.dataroselighter,
    appkitVariables.dataroselight,
    appkitVariables.datarose,
    appkitVariables.datarosedark,

    // Add more colors if needed
];
