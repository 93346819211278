import React, { FC } from 'react';
import { useAuth } from 'react-oidc-context';
import { Navigate, Outlet } from 'react-router-dom';
import { Loading } from '@appkit4/react-components/loading';
import { AppRoutes } from '../../Constants';
import { Timeout } from './Timeout';
import useToast from '../../helpers/useToast';
import useLocale from '../../locale/useLocale';

type ProtectedRedirectProps = { children?: JSX.Element };

const ProtectedRedirect: FC<ProtectedRedirectProps> = ({ children }) => {
    const auth = useAuth();
    const showToast = useToast();
    const { l } = useLocale();

    if (auth.error) {
        showToast(
            l('_unknown'),
            l('_authenticationErrorPleaseLoginAgain'),
            'error'
        );
        return <Navigate to={AppRoutes.LogoutPage} replace />;
    }

    if (auth.isLoading) {
        return <Loading loadingType="circular" indeterminate compact={false} />;
    }

    if (!auth.isAuthenticated) {
        return (
            <Navigate
                to={AppRoutes.LandingPage}
                replace
                state={{ returnRoute: location.pathname }}
            />
        );
    }

    return (
        <>
            {children || <Outlet />}
            <Timeout />
        </>
    );
};

export default ProtectedRedirect;
