import React from 'react';
import { Modal } from '@appkit4/react-components/modal';
import useToast from '../../../../../helpers/useToast';
import useLocale from '../../../../../locale/useLocale';
import ModalContainer from '../../../../ModalContainer';
import CreateContactPersonForm, {
    FormValues,
} from '../../../../CreateContactPersonForm';
import useGenericErrorToast from '../../../../../helpers/useGenericErrorToast';

import { useQueryClient } from '@tanstack/react-query';
import {
    PostV1ContactPersonAdminTenantMutationError,
    getGetV1ContactPersonTenantTenantIdQueryKey,
    getGetV1TenantSuperAdminQueryKey,
    usePostV1ContactPersonAdminTenant,
} from '../../../../../api/generated/endpoint';

interface CreateTenantContactModalProps {
    isVisible: boolean;
    setNotVisible: () => void;
    tenantId: number;
}

const CreateTenantContactModal = (props: CreateTenantContactModalProps) => {
    const showToast = useToast();
    const { l } = useLocale();
    const showGenericErrorToast = useGenericErrorToast();
    const createTenantContact = usePostV1ContactPersonAdminTenant();
    const queryClient = useQueryClient();

    const postNewContactPersonRequest = (values: FormValues) => {
        createTenantContact.mutate(
            {
                data: {
                    tenantId: props.tenantId,
                    ...values,
                },
            },
            {
                onSuccess: () => {
                    showToast(
                        l('_success'),
                        l('_newContactPersonCreated'),
                        'success'
                    );

                    queryClient.invalidateQueries(
                        getGetV1TenantSuperAdminQueryKey()
                    );

                    queryClient.invalidateQueries(
                        getGetV1ContactPersonTenantTenantIdQueryKey(
                            props.tenantId
                        )
                    );
                },
                onError: (
                    error: PostV1ContactPersonAdminTenantMutationError
                ) => {
                    showGenericErrorToast(error);
                },
                onSettled: () => {
                    props.setNotVisible();
                },
            }
        );
    };

    return (
        <>
            {props.isVisible && (
                <ModalContainer isVisible={props.isVisible}>
                    <Modal
                        visible
                        closable
                        closeAriaLabel={l('_close')}
                        title={l('_createNewContactPerson')}
                        aria-label={l('_createNewContactPerson')}
                        modalStyle={{
                            width: '50vw',
                            maxWidth: '500px',
                            minWidth: '250px',
                        }}
                        closeOnPressEscape
                        onCancel={() => props.setNotVisible()}
                    >
                        <CreateContactPersonForm
                            submitFunc={postNewContactPersonRequest}
                        />
                    </Modal>
                </ModalContainer>
            )}
        </>
    );
};

export default CreateTenantContactModal;
