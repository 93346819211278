import { ResponsivePie } from '@nivo/pie';
import { DataVisualisationColors } from './DataVisualisationColors';
import appkitVariables from '../../../../../styles/appkit-variables.module.scss';

interface DonutPieChartProps {
    data: {
        id: string;
        label: string;
        value: number;
    }[];
}

const DonutPieChartTemplate = ({ data }: DonutPieChartProps) => {
    if (data.length === 0) {
        return (
            <div className="no-data">
                <p>No data available</p>
            </div>
        );
    }

    const assignSequentialColors = () => {
        const sequentialColors = [];
        for (let i = 0; i < data.length; i++) {
            sequentialColors.push(
                DataVisualisationColors[i % DataVisualisationColors.length]
            );
        }
        return sequentialColors;
    };

    const sequentialColors = assignSequentialColors();

    const pieTheme = {
        fontSize: 9,
        tooltip: {
            container: {
                fontSize: '12px',
                color: appkitVariables.neutral23,
            },
        },
    };

    return (
        <ResponsivePie
            data={data}
            // Nivo has a color scheme that we could use. Although maybe we would want 'pwc' colors.
            colors={sequentialColors}
            margin={{ top: 0, right: 100, bottom: 0, left: 10 }}
            sortByValue={true}
            enableArcLinkLabels={false}
            innerRadius={0.5}
            padAngle={1.2}
            cornerRadius={2}
            activeOuterRadiusOffset={2}
            motionConfig="wobbly"
            theme={pieTheme}
            legends={[
                {
                    anchor: 'top-right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 15,
                    itemsSpacing: 0,
                    itemWidth: 110,
                    itemHeight: 15,
                    itemTextColor: appkitVariables.neutral10,
                    itemDirection: 'left-to-right',
                    itemOpacity: 2,
                    symbolSize: 8,
                    symbolShape: 'square',
                },
            ]}
        />
    );
};

export default DonutPieChartTemplate;
