import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { NotFound } from '../../../pages';
import Roles from '../../../auth/roles';
import { useGetV1IdentityUserInfo } from '../../../api/generated/endpoint';

type AdminRedirectProps = { children?: JSX.Element };

const AdminRedirect: FC<AdminRedirectProps> = ({ children }) => {
    const { data: userData } = useGetV1IdentityUserInfo({
        query: {
            staleTime: Infinity,
        },
    });

    if (
        userData?.roleId === Roles.AdminId ||
        userData?.roleId === Roles.SuperAdminId
    ) {
        return children || <Outlet />;
    }

    return <NotFound />;
};

export default AdminRedirect;
