import React from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Input } from '@appkit4/react-components/field';
import { Button } from '@appkit4/react-components/button';
import { Tabs, Tab } from '@appkit4/react-components/tabs';
import { RoleResponse } from '../../../../../api/generated/models';
import Roles from '../../../../../auth/roles';
import useLocale from '../../../../../locale/useLocale';

interface CreateUserFormProps {
    roles: RoleResponse[];
    submitFunc: (values: FormValues) => void;
}

export interface FormValues {
    roleId: number;
    forenames: string;
    surname: string;
    email: string;
}

const CreateUserForm = (props: CreateUserFormProps) => {
    const { l } = useLocale();

    const NewUserSchema = Yup.object({
        roleId: Yup.number()
            .min(Roles.UserId, l('_pleaseSelectAValidRole'))
            .max(Roles.SuperAdminId, l('_pleaseSelectAValidRole'))
            .required(l('_pleaseSelectARole')),
        forenames: Yup.string()
            .trim()
            .max(100, l('_pleaseEnterFewerCharacters'))
            .required(l('_pleaseEnterForename(s)')),
        surname: Yup.string()
            .trim()
            .max(100, l('_pleaseEnterFewerCharacters'))
            .required(l('_pleaseEnterASurname')),
        email: Yup.string()
            .email(l('_pleaseEnterAValidEmailAddress'))
            .max(250, l('_pleaseEnterFewerCharacters'))
            .required(l('_pleaseEnterAnEmailAddress'))
            .test(
                'superadminEmailCheck',
                l('_superAdminEmailMustEndWith'),
                function (value: string): boolean {
                    const roleId = this.parent.roleId;

                    if (
                        roleId === Roles.SuperAdminId &&
                        !value.endsWith('@admin.pwc.com')
                    ) {
                        return false;
                    }

                    return true;
                }
            ),
    });

    return (
        <Formik
            initialValues={{
                roleId: Roles.UserId,
                forenames: '',
                surname: '',
                email: '',
            }}
            validationSchema={NewUserSchema}
            onSubmit={(values) => props.submitFunc(values)}
        >
            {({
                values,
                errors,
                touched,
                setFieldTouched,
                setFieldValue,
                validateField,
            }) => (
                <Form>
                    <div className="row focus-margin">
                        <div className="col-auto">
                            <div className="row-auto">
                                <Tabs
                                    type="filled"
                                    onTabChange={(i: number, value: string) => {
                                        setFieldTouched('roleId');
                                        validateField('roleId');
                                        setFieldValue('roleId', +value);
                                    }}
                                    aria-label={l('_newUserRole')}
                                >
                                    {props.roles.map((r) => (
                                        <Tab
                                            label={r.name}
                                            value={r.id.toString()}
                                            key={r.id}
                                        />
                                    ))}
                                </Tabs>
                                {touched.roleId && errors.roleId && (
                                    <div className="error-text">
                                        {errors.roleId}
                                    </div>
                                )}
                            </div>
                            {values.roleId == Roles.SuperAdminId && (
                                <div className="row">
                                    <p className="ap-typography-body-xs ap-text-neutral-10">
                                        {l('_superAdminEmailRequirement')}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md">
                            <Input
                                type="text"
                                title={l('_forename(s)')}
                                placeholder={l('_forename(s)')}
                                required
                                onChange={(value: string) => {
                                    setFieldTouched('forenames');
                                    validateField('forenames');
                                    setFieldValue('forenames', value);
                                }}
                                className="mt-3"
                                data-testid="ForenameTestId"
                            />
                            {touched.forenames && errors.forenames && (
                                <div className="error-text">
                                    {errors.forenames}
                                </div>
                            )}
                        </div>
                        <div className="col-md">
                            <Input
                                type="text"
                                title={l('_surname')}
                                placeholder={l('_surname')}
                                required
                                onChange={(value: string) => {
                                    setFieldTouched('surname');
                                    validateField('surname');
                                    setFieldValue('surname', value);
                                }}
                                className="mt-3"
                                data-testid="SurnameTestId"
                            />
                            {touched.surname && errors.surname && (
                                <div className="error-text">
                                    {errors.surname}
                                </div>
                            )}
                        </div>
                    </div>

                    <Input
                        type="text"
                        title={l('_email')}
                        placeholder={l('_email')}
                        required
                        onChange={(value: string) => {
                            setFieldTouched('email');
                            validateField('email');
                            setFieldValue('email', value);
                        }}
                        className="mt-3"
                        data-testid="EmailTestId"
                    />
                    {touched.email && errors.email && (
                        <div className="error-text">{errors.email}</div>
                    )}

                    <Button
                        className="ms-auto mt-5"
                        data-testid="ButtonSubmitTestId"
                        type="submit"
                    >
                        {l('_createNewUser')}
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

export default CreateUserForm;
