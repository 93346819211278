import React, { useState, FC } from 'react';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import { Tabs, Tab } from '@appkit4/react-components/tabs';
import { AppRoutes } from '../../Constants';
import Roles from '../../auth/roles';
import useLocale from '../../locale/useLocale';
import { useGetV1IdentityUserInfo } from '../../api/generated/endpoint';
import './Navigation.scss';

type AdminTabsProps = { children?: JSX.Element };

const AdminTabs: FC<AdminTabsProps> = ({ children }) => {
    const navigate = useNavigate();
    const { l } = useLocale();
    const defaultTabIndex = 0;
    const location = useLocation();
    const [activeTab, setActiveTab] = useState<number>(() => {
        switch (location.pathname) {
            case AppRoutes.Management:
                return 0;
            case AppRoutes.ApplicationPolicies:
                return 1;
            default:
                return -1;
        }
    });

    const { data: userData } = useGetV1IdentityUserInfo({
        query: {
            staleTime: Infinity,
        },
    });

    return (
        <>
            {/* Only super admin users have access to the tabs to navigate between management and application policies */}
            {userData && userData.roleId >= Roles.SuperAdminId && (
                <>
                    <h1>{l('_adminPanelTitleCase')}</h1>
                    <Tabs
                        type="underline"
                        stretched={false}
                        activeIndex={activeTab}
                        defaultActiveIndex={defaultTabIndex}
                        onTabChange={(i: number, value: string) => {
                            setActiveTab(i);
                            navigate(value);
                        }}
                        responsive
                        showBothIndicators
                        className="mb-6 mt-2 admin-tabs"
                    >
                        <Tab
                            label={l('_management')}
                            value={AppRoutes.Management}
                        />

                        <Tab
                            label={l('_applicationPoliciesTitleCase')}
                            value={AppRoutes.ApplicationPolicies}
                        />
                    </Tabs>
                </>
            )}

            {children || <Outlet />}
        </>
    );
};

export default AdminTabs;
