import React, { useState, useEffect, FC } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from '@appkit4/react-components/modal';
import { Switch } from '@appkit4/react-components/switch';
import { Button } from '@appkit4/react-components/button';
import {
    UserInfoResponse,
    UserPreferencesRequest,
} from '../../../../api/generated/models';
import { AppRoutes } from '../../../../Constants';
import useGenericErrorToast from '../../../../helpers/useGenericErrorToast';
import useToast from '../../../../helpers/useToast';
import {
    usePutV1UserPreferences,
    PutV1UserPreferencesMutationError,
    getGetV1UserPreferencesQueryKey,
    getGetV1IdentityUserInfoQueryKey,
    useGetV1PolicyCookiesVersion,
    getGetV1PolicyCookiesVersionQueryKey,
} from '../../../../api/generated/endpoint';
import useLocale from '../../../../locale/useLocale';
import ModalContainer from './../../../ModalContainer';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

interface CookiesModalProps {
    visible: boolean;
    setVisible: (newValue: boolean) => void;
    userData: UserInfoResponse | undefined;
}

const CookiesModal: FC<CookiesModalProps> = (props) => {
    const showToast = useToast();
    const { l } = useLocale();
    const showGenericErrorToast = useGenericErrorToast();
    const [analyticsCookies, setAnalyticsCookies] = useState<number | null>(-1);
    const [marketingCookies, setMarketingCookies] = useState<number | null>(-1);
    const updateUserPreferences = usePutV1UserPreferences();
    const queryClient = useQueryClient();

    const { data: cookiePolicyVersion, isLoading: loadingCookieVersion } =
        useGetV1PolicyCookiesVersion({
            query: {
                onError: showGenericErrorToast,
                staleTime: Infinity,
            },
        });

    useEffect(() => {
        const isCookiePolicyVersionAPISuccessful =
            !loadingCookieVersion && cookiePolicyVersion !== undefined;

        const haveNotDeniedBothPolicies =
            props.userData?.acceptedAnalyticsCookiesVersion !== -1 ||
            props.userData?.acceptedMarketingCookiesVersion !== -1;

        const hasOutdatedOrUnsetCookiePreference =
            !cookiePolicyVersion?.acceptedVersion ||
            Math.floor(cookiePolicyVersion.acceptedVersion) <
                Math.floor(cookiePolicyVersion.latestVersion);

        if (
            isCookiePolicyVersionAPISuccessful &&
            haveNotDeniedBothPolicies &&
            hasOutdatedOrUnsetCookiePreference
        ) {
            props.setVisible(true);
            setAnalyticsCookies(
                props.userData?.acceptedAnalyticsCookiesVersion ?? -1
            );
            setMarketingCookies(
                props.userData?.acceptedMarketingCookiesVersion ?? -1
            );
        }
    }, [loadingCookieVersion, cookiePolicyVersion]);

    const handleAcceptCookies = () => {
        if (cookiePolicyVersion === undefined) {
            showGenericErrorToast(
                new AxiosError(l('_failedToLoadLatestCookiesPolicyVersion'))
            );
            return;
        }
        const updatedPreference: UserPreferencesRequest = {
            tcVersionAccepted: null, // cannot change tcVersionAccepted here
            emailNotifications: null, // cannot change emailNotifications here
            acceptedAnalyticsCookiesVersion:
                analyticsCookies !== -1 && marketingCookies !== null
                    ? cookiePolicyVersion.latestVersion
                    : -1,
            acceptedMarketingCookiesVersion:
                marketingCookies !== -1 && marketingCookies !== -1
                    ? cookiePolicyVersion.latestVersion
                    : -1,
        };
        updateUserPreferences.mutate(
            { data: updatedPreference },
            {
                onSuccess: () => {
                    props.setVisible(false);
                    showToast(
                        l('_success'),
                        l('_updatedCookiePreferences'),
                        'success'
                    );
                    queryClient.invalidateQueries(
                        getGetV1UserPreferencesQueryKey()
                    );
                    queryClient.invalidateQueries(
                        getGetV1IdentityUserInfoQueryKey()
                    );
                    queryClient.invalidateQueries(
                        getGetV1PolicyCookiesVersionQueryKey()
                    );
                },
                onError: (error: PutV1UserPreferencesMutationError) => {
                    showGenericErrorToast(error);
                },
            }
        );
    };

    return (
        <>
            {props.visible && (
                <ModalContainer isVisible={props.visible}>
                    <Modal
                        visible
                        maskCloseable={false}
                        closable={false}
                        title={l('_cookiesTheChoiceIsYours')}
                        aria-label={l(
                            '_acceptNecessaryAnalyticsAndMarketingCookies'
                        )}
                        onCancel={() => props.setVisible(false)}
                        modalStyle={{ width: '60vw' }}
                        footerStyle={{ marginTop: '20px' }}
                        footer={
                            <Button
                                className="mt-1"
                                onClick={() => handleAcceptCookies()}
                            >
                                {l('_saveCookiePreferences')}
                            </Button>
                        }
                    >
                        <>
                            {cookiePolicyVersion?.acceptedVersion === null ? (
                                <>
                                    <p>
                                        {l('_cookiesDisclaimer')}{' '}
                                        <Link to={AppRoutes.CookiesPage}>
                                            {l('_cookiesDisclaimerContinued')}
                                        </Link>
                                        .
                                    </p>
                                    <br />
                                    <b>{l('_necessaryCookies')}</b>
                                    <p>{l('_necessaryCookiesStatement')}</p>
                                </>
                            ) : (
                                <>
                                    <p>
                                        {l('_our')}{' '}
                                        <Link to={AppRoutes.CookiesPage}>
                                            {l('_cookiePolicy')}
                                        </Link>{' '}
                                        {l(
                                            '_hasBeenUpdatedPleaseReviewThemAndAdjustYourPreferencesBeforeContinuingToTheSite'
                                        )}
                                    </p>
                                </>
                            )}
                            <br />
                            <b>
                                <label htmlFor="analyticsCookiesSwitch">
                                    {l('_analyticsCookies')}
                                </label>
                            </b>
                            <Switch
                                inputId="analyticsCookiesSwitch"
                                checked={
                                    analyticsCookies !== -1 &&
                                    analyticsCookies !== null
                                }
                                onChange={(newValue) =>
                                    setAnalyticsCookies(
                                        newValue
                                            ? cookiePolicyVersion?.latestVersion ??
                                                  null
                                            : -1
                                    )
                                }
                                style={{ float: 'right' }}
                            />
                            {cookiePolicyVersion?.acceptedVersion === null ? (
                                <>
                                    <p>{l('_analyticsCookiesStatement')}</p>
                                    <br />
                                    <p>{l('_analyticsDisclaimer')}</p>
                                </>
                            ) : (
                                <br />
                            )}
                            <br />
                            <b>
                                <label htmlFor="marketingCookiesSwitch">
                                    {l('_marketingCookies')}
                                </label>
                            </b>
                            <Switch
                                inputId="marketingCookiesSwitch"
                                checked={
                                    marketingCookies !== -1 &&
                                    marketingCookies !== null
                                }
                                onChange={(newValue) =>
                                    setMarketingCookies(
                                        newValue
                                            ? cookiePolicyVersion?.latestVersion ??
                                                  null
                                            : -1
                                    )
                                }
                                style={{ float: 'right' }}
                            />
                            {cookiePolicyVersion?.acceptedVersion === null && (
                                <p>{l('_marketingCookiesStatement')}</p>
                            )}
                        </>
                    </Modal>
                </ModalContainer>
            )}
        </>
    );
};

export default CookiesModal;
