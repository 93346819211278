export const enum AppRoutes {
    LandingPage = '/login',
    SettingsPage = '/settings',
    HomePage = '/',
    LogoutPage = '/logout',
    CookiesPage = '/cookies',
    TandC = '/tandc',
    PrivacyPage = '/privacy',
    ContactPage = '/contact',
    AccessibilityStatement = '/accessibility',
    Unauthorized = '/403',
    Management = '/admin/management',
    Tenant = '/admin/management/tenant',
    ApplicationPolicies = '/admin/applicationPolicies',
    BusinessMetrics = '/admin/businessMetrics',
}

export const LoginRedirectStorageKey = 'returnRoute';
